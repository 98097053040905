import { AddCircleRounded } from "@mui/icons-material";
import React, { useEffect, useRef, useState } from "react";
import KmnTextfield from "src/components/shared/KmnTextfield";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { InputAdornment } from "@mui/material";
import { useRecoilValue } from "recoil";
import { atm_merchantState } from "src/state/atomsTransactionData";
import { attributeCode, operatorCode, RULE_MODE } from "./constants";
import { atm_fundingAccounts } from "src/state/atomDashboard";
import CategoryPopUp from "src/components/ManageCategories/CategoryPopUp";
import { atm_ManageCategoriesData } from "src/state/atomManageCategories";

const MerchantRuleFields = ({
  merchantRuleFields,
  data,
  addMerchantField,
  removeMerchantField,
  attributes,
  index,
  handleMerchantCondition,
  getRuleOperators,
  isSaveClicked,
  resetError,
  resetApiError,
  ruleMode,
  keywordsError,
  resetKeywordsError
}) => {
  const valueRef = useRef(null);
  const [operatorsData, setOperatorsData] = useState([]);
  const [isValueDropdownOpen, setIsValueDropdownOpen] = useState(false);
  const showAddIcon =
    merchantRuleFields?.length - 1 === index && merchantRuleFields?.length < 4;
  const showRemoveIcon = merchantRuleFields?.length > 1;
  const selectedOperator = operatorsData?.find(
    (d) => d?.id === data?.condition
  );
  const selectedAttribute = attributes?.find((d) => d?.id === data?.attribute);
  let isValueDropdown =
    selectedOperator?.code === operatorCode.EQUAL &&
    selectedAttribute?.code !== attributeCode.AMOUNT;
  let isValueCaseSensitive = selectedOperator?.code && (selectedOperator?.code !== operatorCode.EQUAL) && selectedAttribute?.code !== attributeCode.AMOUNT;
  let isValueAutocomplete =
    isValueDropdown && selectedAttribute?.code !== attributeCode.ACCOUNT && selectedAttribute?.code !== attributeCode.CATEGORY;
  let isCustomCategoryDropdown = selectedAttribute?.code === attributeCode.CATEGORY;
  const merchantFilterData = useRecoilValue(atm_merchantState);
  const categoryFilterData = useRecoilValue(atm_ManageCategoriesData);
  const fundingAccountsData = useRecoilValue(atm_fundingAccounts);
  const isSelectedAttributeMerchant = selectedAttribute?.code === attributeCode.MERCHANT;
  const isSelectedAttributeAmount =
    selectedAttribute?.code === attributeCode.AMOUNT;
  
  const handleValueOpen = () => {
    setIsValueDropdownOpen(true);
  };
  const handleValueClose = () => {
    setIsValueDropdownOpen(false);
  };

  const getOperators = async (attributeId) => {
    let res = await getRuleOperators(attributeId);
    if(res?.length === 1) {
      handleMerchantCondition({ name: "condition", value: res?.[0]?.id, index });
    }
    setOperatorsData(res);
  }

  const handleFieldChange = async (e) => {
    resetError();
    resetApiError();
    let name = e.target.name || e?.target?.title;
    let value = e.target.value;

    if(isSelectedAttributeMerchant){
      resetKeywordsError();
    }
    
    if (name === "value") {
      handleValueClose();
    }
    
    handleMerchantCondition({ name, value, index });

    if (name === "attribute") {
      getOperators(value);
    }

    if (name !== "value") {
      handleMerchantCondition({ name: "value", value: "", index });
    }
  };

  const getValueOptions = () => {
    let code = selectedAttribute?.code;
    let options = null;
    let optionKey = null;
    let optionValue = null;
    let groupKey = null;
    switch (code) {
      case attributeCode.MERCHANT:
        options = merchantFilterData;
        optionKey = "providerName";
        optionValue = "providerId";
        break;
      case attributeCode.CATEGORY:
        options = categoryFilterData.flatMap((d1) => d1?.categories)
        ?.flatMap((d2) =>
          d2?.categories?.map((d3) => ({
            ...d3,

            name: `${d2?.name} - ${d3?.name}`,
          }))
        );
        optionKey = "name";
        optionValue = "kmnId";
        break;
      case attributeCode.ACCOUNT:
        options = fundingAccountsData;
        optionKey = "accountName";
        optionValue = "userItemAccountId";
        break;
      default:
        options = null;
        optionKey = null;
        optionValue = null;
        groupKey = null;
        break;
    }

    return {
      options,
      optionKey,
      optionValue,
      groupKey,
    };
  };

  const { options, optionKey, optionValue, groupKey } = getValueOptions();
  
  const shouldAddNewField = data?.attribute && data?.condition && ((typeof data?.value === "string" && data?.value?.trim()) || data?.value);

  useEffect(() => {
    if(ruleMode === RULE_MODE.EDIT){
      getOperators(data?.attribute)
    }
  },[ruleMode])

  const addNewField = () => {
    if(shouldAddNewField){
      addMerchantField();
      resetError();
    }
  }

  const removeField = () => {
    if(isSelectedAttributeMerchant){
      resetKeywordsError();
    }
    resetError();
    removeMerchantField(index);
  }

  return (
    <div className="ruleFields">
      <KmnTextfield
        select
        error={isSaveClicked && !data?.attribute}
        errorMessage="Attribute is required"
        label="Attribute"
        value={data?.attribute}
        options={attributes}
        onChange={handleFieldChange}
        name="attribute"
        optionKey="name"
        optionValue="id"
        optionClassName="rulesOption"
        optionProps={{
          hidden: (option) => {
            return merchantRuleFields?.some(
              (field) => field?.attribute === option?.value
            );
          },
        }}
      />
      <div className="fieldCover">
        <KmnTextfield
          select
          fullWidth
          error={isSaveClicked && !data?.condition}
          errorMessage="Condition is required"
          value={operatorsData?.length ? data?.condition : ""}
          label="Condition"
          options={operatorsData}
          onChange={handleFieldChange}
          name="condition"
          optionKey="name"
          optionValue="id"
          optionClassName="rulesOption"
        />
      </div>
      <KmnTextfield
        className="valueField"
        helperText={isValueCaseSensitive && "String is case sensitive"}
        error={(isSaveClicked && ((typeof data?.value === "string" && !data?.value?.trim()) || !data?.value)) || !!(isSelectedAttributeMerchant && keywordsError)}
        errorMessage={isSelectedAttributeMerchant && keywordsError ? null : "Value is required"}
        open={isValueDropdownOpen}
        autoComplete={isValueAutocomplete}
        select={isValueDropdown}
        groupKey={groupKey}
        inputRef={valueRef}
        label="Value"
        name="value"
        value={data?.value}
        onChange={handleFieldChange}
        options={options}
        optionKey={optionKey}
        optionValue={optionValue}
        placeholder={isSelectedAttributeAmount ? "0.00" : ""}
        onOpen={handleValueOpen}
        onClose={handleValueClose}
        type="tel"
        inputProps={(isValueDropdown || isSelectedAttributeAmount) ? undefined : { maxLength: 64 }}
        InputProps={
          !isValueDropdown &&
          isSelectedAttributeAmount ? {
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          } : undefined
        }
        onInput={(e) => {
          if (isSelectedAttributeAmount) {
            e.target.value = e.target.value.replace(/[^0-9.]/g, "");
          }
        }}
        customDropdown={isCustomCategoryDropdown}
      />
      {valueRef.current?.node && isCustomCategoryDropdown && <CategoryPopUp
        anchorValue={valueRef.current?.node}
        isOpen={isValueDropdownOpen}
        onClose={handleValueClose}
        name="value"
        onValueChange={handleFieldChange}
        createHidden={true}
      />}
      <div className="fieldActionBtns">
        {showRemoveIcon && (
          <RemoveCircleIcon
            className="removeIcon"
            onClick={removeField}
          />
        )}
        {showAddIcon && (
          <AddCircleRounded
            className="addIcon"
            style={!shouldAddNewField ? {
              color: "var(--colorBlack9)",
              cursor: 'not-allowed'
            } : undefined}
            onClick={addNewField}
          />
        )}
      </div>
    </div>
  );
};

export default MerchantRuleFields;
