import React, { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import FiltersMob from "../../assets/images/filters-mob.svg";

import useSpendByCategory from "./useSpendByCategory";
import {
  atm_InsightsCategoryList,
  atm_IsMobCatFilter,
  atm_globalCategory,
} from "src/state/insightsCategories";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { usdFormate } from "src/global/central";
import { ArrowRightRounded } from "@mui/icons-material";
import { Box } from "@mui/material";

const SidebarCategory = () => {
  const { data, fns } = useSpendByCategory();

  const categoryList = useRecoilValue(atm_InsightsCategoryList);

  const [globalCategory, setGlobalCategory] =
    useRecoilState(atm_globalCategory);

  const setIsMobFilter = useSetRecoilState(atm_IsMobCatFilter);

  const categoryListRows = categoryList.map((categoryData, index) => ({
    id: categoryData.subCategoryId,
    col1: index + 1,
    col2: {
      logo: categoryData.categoryLogURL,
      category: categoryData.categoryName,
      subCategory: categoryData.subCategoryName,
    },
    col3: {
      price: categoryData.spend,
      percentage: categoryData.spendPercentage,
    },
  }));

  const categoryListColumns = [
    {
      field: "col1",
      headerClassName: "insight-grid-head",
      headerName: "#",
      width: "50",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "col2",
      headerClassName: "insight-grid-head categoryHead",
      headerName: "CATEGORY",
      flex: 1,
      paddingLeft: "0px",
      headerAlign: "left",
      cellClassName: "category-cell",
      valueGetter: (value) => value?.category,
      renderCell: (params) => (
        <Box
          sx={{ display: "flex", alignItems: "center", gap: 1.5 }}
          className="custom-div"
        >
          {params.row.col2.logo && (
            <img height={30} width={30} srcSet={params.row.col2.logo} alt="" />
          )}
          <div>
            <div className="category">{params.row.col2.category}</div>
            <div className="sub-category">{params.row.col2.subCategory}</div>
          </div>
        </Box>
      ),
    },
    {
      field: "col3",
      headerClassName: "insight-grid-head total-spend-head",
      headerName: "TOTAL SPEND",
      headerAlign: "right",
      align: "right",
      cellClassName: "amount-cell",
      valueGetter: (value) => value?.price,
      renderCell: (params) => (
        <div className="custom-div">
          <div className="price" style={{ whiteSpace: "nowrap" }}>
            {usdFormate(params.row.col3.price)}
          </div>
          <div className="percentage" style={{ textAlign: "end" }}>
            {`${Math.round(params.row.col3.percentage)}%`}
            <ArrowRightRounded />
          </div>
        </div>
      ),
    },
  ];

  const getRowSpacing = ({ isFirstVisible, isLastVisible }) => ({
    top: isFirstVisible ? 0 : 4,
    bottom: isLastVisible ? 0 : 4,
  });

  console.log(globalCategory);
  
  return (
    <div className="sidebar-cover" style={{ maxWidth: "100%", width: 700 }}>
      <div className="sidebar">
        {data.categoryDataLoading && (
          <LinearProgress
            style={{ width: "100%", position: "absolute", top: 0, left: 0 }}
          />
        )}
        <header className="category-list-header mobile">
          <span>Insights - Spend by Category</span>
          <div className="filter-cover">
            <img onClick={() => setIsMobFilter(true)} src={FiltersMob} alt="" />
          </div>
        </header>
        <DataGrid
          getRowSpacing={getRowSpacing}
          rows={categoryListRows}
          columns={categoryListColumns}
          getRowHeight={() => "auto"}
          onRowClick={fns.onCategorySelected}
          rowSelectionModel={globalCategory ? [globalCategory] : []}
          hideFooterSelectedRowCount
          pagination
          hideFooter={categoryListRows?.length <= 100}
        />
        <div className="total">
          <div className="text">Total</div>
          <div className="price">{usdFormate(categoryList[0]?.userTxnSpend || 0)}</div>
        </div>
      </div>
    </div>
  );
};

export default SidebarCategory;
