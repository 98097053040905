const forbiddenKeywords = ['select', 'update', 'delete', 'drop', 'truncate', 'insert', 'union'];
const forbiddenCharacters = /[!'=>;<>\"&|%*(){}\[\]]/g;
const sqlInjectionPatterns = /(\bunion\b|\bselect\b|\binsert\b|\bdrop\b|\bupdate\b|\bdelete\b|\btruncate\b|--|\bexec\b|\bor\b|1\s*=\s*1|\bwaitfor\b|\bshutdown\b)/gi;

export function checkHarmfulKeywords(value) {
  if (typeof value !== 'string' || !value.trim()) return;

  let foundKeywords = [];
  let totalFoundKeywords = [];

  // Normalize the input by lowercasing and removing extra whitespace
  const lowerCaseValue = value.toLowerCase().trim();

  // Check for specific SQL keywords
  forbiddenKeywords.forEach((keyword) => {
    const keywordPattern = new RegExp(`\\b${keyword}\\b`, 'g');
    if (keywordPattern.test(lowerCaseValue)) {
      foundKeywords.push(keyword);
    }
  });

  // Check for forbidden characters
  const foundCharacters = value.match(forbiddenCharacters);
  if (foundCharacters) {
    totalFoundKeywords = [...foundKeywords, ...new Set(foundCharacters)];
  } else {
    totalFoundKeywords = [...foundKeywords];
  }

  // Avoid generic "SQL injection pattern detected" by showing exact matches
  if (!foundKeywords.length && sqlInjectionPatterns.test(lowerCaseValue)) {
    // Extract the specific matches for user-friendly output
    const matches = lowerCaseValue.match(sqlInjectionPatterns);
    totalFoundKeywords = totalFoundKeywords.concat(matches);
  }

  let errorMessage = "";
  if (totalFoundKeywords.length) {
    const uniqueErrors = Array.from(new Set(totalFoundKeywords));
    const suffix = uniqueErrors.length === 1 ? "keyword is not allowed" : "keywords are not allowed";
    const keywordsString = uniqueErrors.map(keyword => `“${keyword}”`).join(", ");
    errorMessage = `${keywordsString} ${suffix}`;
  }

  return errorMessage;
}
