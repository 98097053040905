/**********************************************************************
 *
 *   Component generated by Quest
 *
 *   WARNING: By editing this component by hand, you will lose the ability to regenerate the code without conflicts.
 *   To preseve that abilty, always export from Quest to regenerate this file.
 *   To setup props, bindings and actions, use the Quest editor
 *   Code Logic goes in the hook associated with this component
 *
 *   For help and further details refer to: https://www.quest.ai/docs
 *
 *
 **********************************************************************/

import { styled } from "@mui/material/styles";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { Alert, Box, Modal } from "@mui/material";
import transactionBg from "../../../components/assets/images/bg-transaction.png";
import transactionBgMob from "../../../components/assets/images/bg-insights.png";
import { TrasactionRightSide } from "./TrasactionRightSide";
import "../../assets/css/transaction.css";
import { Helmet } from "react-helmet";

import {
  atm_IsMobTransactionFilter,
  atm_SelectedTransDateFilter,
  atm_fetchMorePage,
  atm_isManageCategoryBox,
  atm_isManageRulesModal,
  atm_isReviewTransBoxOpen,
  atm_pageOptions,
  atm_totalTransData,
  atm_transactionsListTable,
} from "../../../state/atomsTransactionData";

import useTransactionsLanding from "./useTransactionsLanding";
import { useEffect, useState } from "react";

import { AccountList } from "../AccountList";
import { GetWindowWidth } from "../../../global/central";
import FiltersTransaction from "./FiltersTransaction";
import TransUserReview from "./ParentReview";
import useManegeCategories from "src/components/ManageCategories/useManageCategories";
import {
  atm_isAccountSelectionModified,
  atm_selectedAccountIds,
} from "src/state/atomsAccountList";
import AccountNavigation from "../AccountNavigation";
import MetaTags from "src/components/shared/MetaTags";
import KmnButton from "./KmnButton";

function AccountLanding() {
  const { dataTransactions, fnsTransactions } = useTransactionsLanding();
  const { fnsManageCategories } = useManegeCategories();

  const transactionsListTable = useRecoilValue(atm_transactionsListTable);

  const setTransactionDateFilterValue = useSetRecoilState(
    atm_SelectedTransDateFilter
  );

  const setTransactionFilter = useSetRecoilState(atm_IsMobTransactionFilter);

  const [mobOptions, setMobOptions] = useState(1);

  const pageOptions = useRecoilValue(atm_pageOptions);

  const selectedAccountIds = useRecoilValue(atm_selectedAccountIds);

  const [isAccountSelectionModified, setIsAccountSelectionModified] =
    useRecoilState(atm_isAccountSelectionModified);

  const width = GetWindowWidth();

  const [isTransBoxOpen, setIsTransBoxOpen] = useRecoilState(
    atm_isReviewTransBoxOpen
  );

  const isManageCategoryBox = useRecoilValue(atm_isManageCategoryBox);

  const setTotalTransData = useSetRecoilState(atm_totalTransData);

  const setFetchMorePage = useSetRecoilState(atm_fetchMorePage);

  const setIsManageRulesModal = useSetRecoilState(atm_isManageRulesModal)

  const modelStyle = {
    position: "relative",
    top: width > 900 ? "10%" : "5%",
    left: "50%",
    transform: "translateX(-50%)",
    maxWidth: "90%",
    width: 773,
  };
  

  useEffect(() => {
    document.getElementById("infinite-scroller").scrollTop = 0;
    setFetchMorePage(1);
    setTotalTransData(dataTransactions.transactionList);
  }, [mobOptions]);

  useEffect(() => {
    if (isAccountSelectionModified) {
      fnsTransactions.refreshTransactionDataFromProvider(
        pageOptions.pageSize,
        pageOptions.pageNumber
      );
    }
  }, [selectedAccountIds]);

  useEffect(() => {
    fnsTransactions.onTransactionsLandingPageLoad();
    fnsManageCategories.getCategoriesData();
    fnsTransactions.getTransactionTags();

    return () => {
      setTransactionDateFilterValue(12);
      setTransactionFilter(false);
      setIsAccountSelectionModified(false);
    };
  }, []);

  const triggerManageRulesModal = () => {
    setIsManageRulesModal(true);
  }

  return (
    <>
      <MetaTags index={"Transactions"} />
      <div id="container-fastlink"></div>
      <Modal open={isTransBoxOpen} onClose={() => setIsTransBoxOpen(false)}>
        <Box sx={modelStyle}>
          <TransUserReview />
        </Box>
      </Modal>
      <img
        src={width > 900 ? transactionBg : transactionBgMob}
        alt=""
        className="transactionbg"
      />
      <div className="transaction-body-wrapper-area transaction-page">
        {width < 900 && <div className="accountNavigationTransactions"> <AccountNavigation /> <KmnButton variant='secondary' color="primary" onClick={triggerManageRulesModal}>Rules</KmnButton> </div>}
        <div
          className={`transaction-body-inner-area ${
            width < 900 &&
            ((mobOptions === 1 && "active-ALL") ||
              (mobOptions === 2 && "active-MY-ACCOUNT"))
          } ${isManageCategoryBox && "manageCategoryBoxOpened"}`}
        >
          {/* filters  */}
          <FiltersTransaction
            transactionsListTable={transactionsListTable}
            MerchantState={dataTransactions.MerchantState}
          />

          <AccountList mobOptions={mobOptions} setMobOptions={setMobOptions} />

          <TrasactionRightSide
            isTransactionListLoading={dataTransactions.isTransactionListLoading}
            transactionsListTable={transactionsListTable}
            clmnTransactions={dataTransactions.clmnTransactions}
            MerchantState={dataTransactions.MerchantState}
            mobOptions={mobOptions}
            setMobOptions={setMobOptions}
          />
        </div>
      </div>
    </>
  );
}

export default AccountLanding;
