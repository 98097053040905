import { useCallback, useState } from "react";
import useDashboard from "src/components/Dashboard/useDashboard";
import useApi from "src/global/useApi";
import { RULES_ERROR_TYPE } from "./constants";

export default function useRules() {
  const { fnsDashboard, dataDashboard } = useDashboard();
  const {
    callApi: callGetRuleAttributes,
    data: ruleAttributes,
    loading: ruleAttributesLoading,
  } = useApi();

  const {
    callApi: callGetRuleOperators,
    data: ruleOperators,
    loading: ruleOperatorsLoading,
  } = useApi();

  const {
    callApi: callGetAllRules,
    data: rulesData,
    loading: rulesLoading,
  } = useApi();
  const {
    callApi: callDeleteRule,
    loading: deleteRuleLoading,
  } = useApi();

  const { callApi: callCreateRule, loading: createRuleLoading } = useApi();

  const { callApi: callEditRule, loading: editRuleLoading } = useApi();
  
  const { callApi: callSetRunStatus, loading: runStatusLoading } = useApi();

  const { callApi: callGetMatchingTransactionCountForRule, data: matchingRuleTransactionCount, loading: getMatchTransactionsCountLoading, resetData: resetTransactionCount } = useApi();

  const [error, setError] = useState(null);
  const [errorType, setErrorType] = useState(null);
  
  const resetError = useCallback(() => {
    if(error){
      setError(null);
    }
  },[error])
  
  const loading =
    ruleAttributesLoading ||
    ruleOperatorsLoading ||
    dataDashboard.goalsLoading ||
    createRuleLoading || editRuleLoading || runStatusLoading || getMatchTransactionsCountLoading;

  function getApi(endpoint) {
    return process.env.REACT_APP_ACCOUNT_API_END_POINT + endpoint;
  }

  function getAllRules() {
    let api = getApi("/accounts/rules/v1/listRules");
    callGetAllRules(api, {});
  }

  function getRuleAttributes() {
    let api = getApi("/accounts/rules/v1/getAttributes");
    callGetRuleAttributes(api, {});
  }

  async function createRule(rule) {
      let api = getApi("/accounts/rules/v1/createRule");
      let payload = {
        ruleName: rule?.ruleName,
        applyFromDate: new Date().toISOString(),
        conditions: rule?.leftRules?.map((d, idx) => ({
          sequenceNo: idx + 1,
          attributeId: d?.attribute,
          operatorId: d?.condition,
          attributeIdValue: d?.value,
        }))?.filter(d => d?.attributeId && d?.attributeIdValue && d?.attributeIdValue),
        results: rule?.rightRules?.map((d, idx) => ({
          sequenceNo: idx + 1,
          attributeId: d?.attribute,
          attributeIdValue: d?.value,
        }))?.filter(d => d?.attributeId && d?.attributeIdValue),
        resultsTags: rule?.tags?.map(d => ({tagId: d?.tagId})),
      };
    try{
      await callCreateRule(api, payload);
      getAllRules();
    }catch(err){
      setError(err);
      setErrorType(RULES_ERROR_TYPE.CREATE)
      throw err;
    }
  }

  async function deleteRule(id){
    let api = getApi("/accounts/rules/v1/deleteRule");
    let payload = {
      ruleId: id
    }
    try{
      await callDeleteRule(api, payload);
      getAllRules();
    }catch(err){
      setError(err);
      setErrorType(RULES_ERROR_TYPE.DELETE);
      throw err;
    }
  }

  async function getRuleOperators(id) {
    let api = getApi("/accounts/rules/v1/getOperatorsForAttribute");
    let payload = {
      attributeId: id,
    };
    let res = await callGetRuleOperators(api, payload);
    return res;
  }

  async function getRuleValues() {
    fnsDashboard.getFundingAccounts();
  }

  async function editRule(rule){
    let api = getApi("/accounts/rules/v1/editRule");
    let payload = {
      ruleId: rule?.id,
      ruleName: rule?.ruleName,
      applyFromDate: new Date().toISOString(),
      conditions: rule?.leftRules?.map((d, idx) => ({
        conditionId: d?.id,
        sequenceNo: idx + 1,
        attributeId: d?.attribute,
        operatorId: d?.condition,
        attributeIdValue: d?.value,
      }))?.filter(d => d?.attributeId && d?.attributeIdValue && d?.attributeIdValue),
      results: rule?.rightRules?.map((d, idx) => ({
        resultId: d?.id,
        sequenceNo: idx + 1,
        attributeId: d?.attribute,
        attributeIdValue: d?.value,
      }))?.filter(d => d?.attributeId && d?.attributeIdValue),
      resultsTags: rule?.tags?.map(d => ({resultsTagsId: d?.resultsTagsId, tagId: d?.tagId})),
    };
    try{
      await callEditRule(api, payload);
      getAllRules();
    }catch(err){
      setError(err);
      setErrorType(RULES_ERROR_TYPE.EDIT);
      throw err;
    }
  }

  async function setRunStatus(payload) {
    let api = getApi("/accounts/rules/v1/setRunStatus");
    try{
      await callSetRunStatus(api, payload);
    }catch(err){
      setError(err);
      setErrorType(RULES_ERROR_TYPE.EDIT);
      throw err;
    }finally{
      getAllRules();
    }
  }

  async function getMatchingTransactionCountForRule(rule){
    let api = getApi("/accounts/rules/v1/getMatchingTransactionCountForRule")
    let payload = {
      ruleName: rule?.ruleName,
      applyFromDate: new Date().toISOString(),
      conditions: rule?.leftRules?.map((d, idx) => ({
        sequenceNo: idx + 1,
        attributeId: d?.attribute,
        operatorId: d?.condition,
        attributeIdValue: d?.value,
      }))?.filter(d => d?.attributeId && d?.attributeIdValue && d?.attributeIdValue),
      results: rule?.rightRules?.map((d, idx) => ({
        sequenceNo: idx + 1,
        attributeId: d?.attribute,
        attributeIdValue: d?.value,
      }))?.filter(d => d?.attributeId && d?.attributeIdValue),
      resultsTags: rule?.tags?.map(d => ({tagId: d?.tagId})),
    };
    try{
      await callGetMatchingTransactionCountForRule(api, payload)
    }catch(err){
      setError("An error occured while processing your changes.");
      setErrorType(RULES_ERROR_TYPE.EDIT);
      throw err;
    }
  }

  let data = {
    ruleAttributes,
    ruleOperators,
    ruleOperatorsLoading,
    ruleAttributesLoading,
    rulesData,
    loading,
    deleteRuleLoading,
    rulesLoading,
    error,
    errorType,
    matchingRuleTransactionCount
  };

  let fns = {
    getRuleAttributes,
    getRuleOperators,
    getRuleValues,
    getAllRules,
    createRule,
    deleteRule,
    editRule,
    resetError,
    setRunStatus,
    getMatchingTransactionCountForRule,
    resetTransactionCount
  };

  return { data, fns };
}
