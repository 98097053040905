import React, { useEffect, useRef, useState } from "react";
import KmnModal from "../../shared/KmnModal";
import { InputAdornment } from "@mui/material";
import { Col } from "../../shared";
import { useRecoilState } from "recoil";
import { atm_isAddManualAccountModal } from "src/state/atomsTransactionData";
import useDeviceType from "src/hooks/useDeviceType";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import useAddManualAccount from "./useAddManualAccount";
import KmnTextfield from "src/components/shared/KmnTextfield";
import { MANUAL_ACCOUNT_FIELDS } from "./constants";

export const AddManualAccountModal = () => {
  const [isAddManualAccountModal, setAddManualAccountModal] = useRecoilState(
    atm_isAddManualAccountModal
  );
  const { isMobile } = useDeviceType();
  const [isEditTapped, setIsEditTapped] = useState(false);
  const accountNameRef = useRef(null);
  const { data, fns } = useAddManualAccount();
  const [userEntries, setUserEntries] = useState({
    [MANUAL_ACCOUNT_FIELDS.ACCOUNT_TYPE]: null,
    [MANUAL_ACCOUNT_FIELDS.ACCOUNT_SUB_TYPE]: null,
    [MANUAL_ACCOUNT_FIELDS.ACCOUNT_NAME]: "",
    [MANUAL_ACCOUNT_FIELDS.ACCOUNT_BALANCE]: "",
  });
  const [error, setError] = useState(null);
  const isSaveDisabled =
    !userEntries[MANUAL_ACCOUNT_FIELDS.ACCOUNT_TYPE] ||
    !userEntries[MANUAL_ACCOUNT_FIELDS.ACCOUNT_SUB_TYPE] ||
    !userEntries[MANUAL_ACCOUNT_FIELDS.ACCOUNT_NAME]?.trim() ||
    userEntries[MANUAL_ACCOUNT_FIELDS.ACCOUNT_BALANCE] === "" ||
    data.isLoading;

  const handleClose = () => {
    setAddManualAccountModal(false);
  };

  const onFieldChange = (e) => {
    let name = e.target.name;
    let value = e.target.value;

    setError(null);
    if (name === "accountType" && value !== userEntries.accountType) {
      fns.getAllSubAccountTypes(value);
    }

    setUserEntries((prev) => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    let isSuccess = await fns.addManualAccount(userEntries);
    if(isSuccess){
      handleClose();
    }
  };

  useEffect(() => {
    if (accountNameRef.current && isMobile) {
      if (isEditTapped) {
        accountNameRef.current.focus();
        const length = accountNameRef.current.value.length;
        accountNameRef.current.setSelectionRange(length, length);
      } else {
        accountNameRef.current.scrollLeft = 0;
      }
    }
  }, [isEditTapped, isMobile]);

  useEffect(() => {
    setError(data.error);
  }, [data.error]);
  return (
    <KmnModal
      className="addManualAccountModal"
      isOpen={isAddManualAccountModal}
      onClose={handleClose}
      title="Add Manual Account"
      loading={data.isLoading}
      onConfirm={handleSave}
      disableConfirm={isSaveDisabled}
      error={error}
      body={
        <Col gap={isMobile ? 30 : 20} className="bodyInside">
          <KmnTextfield
            select
            label="Type"
            helperText="Choose account type"
            options={data.allAccountsData}
            optionKey={"accountType"}
            optionValue={"id"}
            optionClassName="addManualAccountOption"
            name={MANUAL_ACCOUNT_FIELDS.ACCOUNT_TYPE}
            value={userEntries?.[MANUAL_ACCOUNT_FIELDS.ACCOUNT_TYPE]}
            onChange={onFieldChange}
          />
          <KmnTextfield
            select
            label="Sub-Type"
            helperText="Choose account sub-type"
            options={data.allSubAccountsData}
            optionKey={"accountSubType"}
            optionValue={"id"}
            optionClassName="addManualAccountOption"
            name={MANUAL_ACCOUNT_FIELDS.ACCOUNT_SUB_TYPE}
            value={userEntries?.[MANUAL_ACCOUNT_FIELDS.ACCOUNT_SUB_TYPE]}
            onChange={onFieldChange}
          />
          <div className="accountName-balance">
            <KmnTextfield
              inputRef={accountNameRef}
              className="accountName"
              multiline
              helperText="Enter account name"
              label="Account Name"
              onInput={(e) => {
                e.target.value = e.target.value.replace(
                  /[^a-zA-Z0-9\s&-]/g,
                  ""
                );
              }}
              onFocus={() => setIsEditTapped(true)}
              InputProps={
                isMobile && !isEditTapped
                  ? {
                      endAdornment: (
                        <InputAdornment position="end">
                          <ModeEditIcon
                            className="editIcon"
                          />
                        </InputAdornment>
                      ),
                    }
                  : undefined
              }
              onBlur={() => setIsEditTapped(false)}
              name={MANUAL_ACCOUNT_FIELDS.ACCOUNT_NAME}
              value={userEntries?.[MANUAL_ACCOUNT_FIELDS.ACCOUNT_NAME]}
              onChange={onFieldChange}
            />
            <KmnTextfield
              className="balance"
              label="Value"
              placeholder="0.00"
              type="tel"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
              onInput={(e) => {
                e.target.value = e.target.value.replace(/[^0-9.]/g, "");
              }}
              name={MANUAL_ACCOUNT_FIELDS.ACCOUNT_BALANCE}
              value={userEntries?.[MANUAL_ACCOUNT_FIELDS.ACCOUNT_BALANCE]}
              onChange={onFieldChange}
            />
          </div>
        </Col>
      }
      btnVariant={!isMobile ? "tertiary" : "secondary"}
      onCancel={handleClose}
    />
  );
};
