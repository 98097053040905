import { LinearProgress } from "@mui/material";
import React from "react";

const Loader = ({ visible, sx = {}, ...otherProps }) => {
  if (visible) {
    return (
      <LinearProgress
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          ...sx
        }}
        {...otherProps}
      />
    );
  } else {
    return null;
  }
};

export default Loader;
