import React, { useEffect, useState } from "react";
import Done from "@mui/icons-material/Done";
import { LinearProgress } from "@mui/material";
import { LineChart } from "@mui/x-charts";

const PopChart = ({ data, loading }) => {
  const [showCountry, setShowCountry] = useState(1);
  const [showState, setShowState] = useState(1);
  const [showCounty, setShowCounty] = useState(1);
  const [showCity, setShowCity] = useState(1);
  const [showZip, setShowZip] = useState(1);
  const [seriesData, setSeriesData] = useState([
    {
      name: "Zip",
      data: [],
    },
    {
      name: "City",
      data: [],
    },
    {
      name: "County",
      data: [],
    },
    {
      name: "State",
      data: [],
    },
    {
      name: "Country",
      data: [],
    },
  ]);

  useEffect(() => {
    if (data && Object.keys(data).length) {
      const newSeriesData = [];
      const { city, country, county, state, zip } = data;

      if (showZip) {
        newSeriesData.push({
          name: "Zip",
          data: Object.values(zip || []),
          color: "#c95266",
        });
      }

      if (showCity) {
        newSeriesData.push({
          name: "City",
          data: Object.values(city || []),
          color: "#ef9e8e",
        });
      }

      if (showCounty) {
        newSeriesData.push({
          name: "County",
          data: Object.values(county || []),
          color: "#23CBE3",
        });
      }
      if (showState) {
        newSeriesData.push({
          name: "State",
          data: Object.values(state || []),
          color: "#EF8274",
        });
      }
      if (showCountry) {
        newSeriesData.push({
          name: "Country",
          data: Object.values(country || []),
          color: "#41DAC4",
        });
      }
      setSeriesData(newSeriesData);
    }
  }, [data, showZip, showCity, showCounty, showState, showCountry]);

  const handleCheckboxChange = (id) => {
    switch (id) {
      case "Zip":
        setShowZip(!showZip);
        break;
      case "City":
        setShowCity(!showCity);
        break;
      case "County":
        setShowCounty(!showCounty);
        break;
      case "State":
        setShowState(!showState);
        break;
      case "Country":
        setShowCountry(!showCountry);
        break;
      default:
        break;
    }
  };

  return (
    <div className="chartContainerCover">
      <div className="chart-container pop-chart">
        {loading && (
          <LinearProgress
            style={{
              width: "100%",
              position: "absolute",
              top: 0,
              left: 0,
            }}
          />
        )}
        <div className="chart-header">
          <span className="header-text">Spend % by population density</span>
          <div className="checkboxes-cover">
            <div className="checkboxes">
              <div className="box">
                <input
                  hidden
                  id="Zip"
                  type="checkbox"
                  checked={showZip}
                  onChange={() => handleCheckboxChange("Zip")}
                />
                <label htmlFor="Zip">
                  <Done
                    fontSize="small"
                    style={{ color: "var(--colorWhite)", fontSize: 13 }}
                  />
                </label>
                Zip
              </div>
              <div className="box">
                <input
                  hidden
                  id="City"
                  type="checkbox"
                  checked={showCity}
                  onChange={() => handleCheckboxChange("City")}
                />
                <label htmlFor="City">
                  <Done
                    fontSize="small"
                    style={{ color: "var(--colorWhite)", fontSize: 13 }}
                  />
                </label>
                City
              </div>
              <div className="box">
                <input
                  hidden
                  id="County"
                  type="checkbox"
                  checked={showCounty}
                  onChange={() => handleCheckboxChange("County")}
                />
                <label htmlFor="County">
                  <Done
                    fontSize="small"
                    style={{ color: "var(--colorWhite)", fontSize: 13 }}
                  />
                </label>
                County
              </div>
              <div className="box">
                <input
                  hidden
                  id="State"
                  type="checkbox"
                  checked={showState}
                  onChange={() => handleCheckboxChange("State")}
                />
                <label htmlFor="State">
                  <Done
                    fontSize="small"
                    style={{ color: "var(--colorWhite)", fontSize: 13 }}
                  />
                </label>
                State
              </div>
              <div className="box">
                <input
                  hidden
                  id="Country"
                  type="checkbox"
                  checked={showCountry}
                  onChange={() => handleCheckboxChange("Country")}
                />
                <label htmlFor="Country">
                  <Done
                    fontSize="small"
                    style={{ color: "var(--colorWhite)", fontSize: 13 }}
                  />
                </label>
                Country
              </div>
            </div>
          </div>
        </div>
        <LineChart
          margin={{ bottom: 90, top: 25, left: 80 }}
          xAxis={[
            {
              label: "% spend in category",
              data: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
              valueFormatter: (val, context) => {
                let value = val > 0 ? `${val}%` : val;
                return context.location === "tick"
                  ? `${value}`
                  : `spend in category: ${value}`;
              },
              tickMinStep: 10,
            },
          ]}
          yAxis={[
            {
              label: "% population",
              max: 100,
              min: 0,
              tickNumber: 5,
              valueFormatter: (value) => {
                return value > 0 ? `${value}%` : "";
              },
            },
          ]}
          series={seriesData?.map((d) => ({
            label: (location) =>
              location === "tooltip" ? `population (${d?.name})` : d?.name,
            data: [0, ...d?.data],
            color: d?.color || "",
            valueFormatter: (value) => `${value}%`,
            showMark: false,
          }))}
          slotProps={{
            legend: {
              position: {
                vertical: "bottom",
                horizontal: 'middle'
              },
              itemMarkHeight: 14,
              itemMarkWidth: 14,
              itemGap: 12,
              labelStyle: {
                fontWeight: 700,
                fontSize: 12,
              },
            },
          }}
          sx={{
            height: "100%",
            ".MuiChartsAxis-label": {
              transform: "translateX(-20px)",
            },
            ".MuiChartsLegend-series": {
              fontWeight: 700,
            },
          }}
        />
      </div>
    </div>
  );
};

export default PopChart;
