import React, { useEffect } from "react";
import {
  areaElementClasses,
  LineChart,
  lineElementClasses,
  markElementClasses,
} from "@mui/x-charts/LineChart";
import { usdFormate } from "src/global/central";
import Row from "../Row";
import {
  FormControl,
  LinearProgress,
  MenuItem,
  Select,
  useMediaQuery,
} from "@mui/material";
import { useDrawingArea, useYScale } from "@mui/x-charts";
import { ChartsReferenceLine } from "@mui/x-charts";
import useDateFilter from "../DateFilter/useDateFilter";
import { useRecoilState } from "recoil";
import { atm_dateFilterNetWorth } from "src/state/atomDashboard";
import useDashboard from "src/components/Dashboard/useDashboard";

const seriesColorGreen = "var(--color23)";
const seriesColorRed = "var(--color14)";

const LineChartFilled = (props) => {
  const {fnsDashboard} = useDashboard();
  const {fns: fnsFilters, data: dataFilters} = useDateFilter();
  const { label = "Net Worth", loader, data } = props;
  const isMobile = useMediaQuery("(max-width: 750px)");
  const [filter, setFilter] = useRecoilState(atm_dateFilterNetWorth);
  
  const isNegativeData = data.some(d => d?.amount < 0);
  function formatDateString(dateString) {
    const date = new Date(dateString);
  
    const day = date.getUTCDate();
    const month = date.toLocaleString("en-US", { month: "short", timeZone: "UTC" });
    const year = date.getUTCFullYear().toString().slice(-2);
  
    return `${month} ${day} '${year}`;
  }
  
  const handleDropdown = (e) => {
    setFilter(e.target.value);
  };

  const CustomItemTooltipContent = (props) => {
    const { classes, axisData, dataIndex } = props;
    let value = data[dataIndex]?.amount;
    let month = axisData?.x?.value;

    return (
      <div className={`custom-${classes.root}`}>
        <table className={classes.table}>
          <thead>
            <tr className={classes.row}>
              <th colSpan={3} className={classes.cell}>
                {month}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className={classes.row}>
              <td className={classes.markCell}>
                <div
                  className={classes.mark}
                  style={{ background: value > 0 ? seriesColorGreen : value < 0 ? seriesColorRed : "var(--color17)" }}
                ></div>
              </td>
              <td className={classes.labelCell}>Net Worth</td>
              <td className={classes.valueCell}>
                {!value ? "-" : usdFormate(value)?.slice(0, -1)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const ColorSwitch = ({ id }) => {
    const { top, height, bottom } = useDrawingArea();
    const svgHeight = top + bottom + height;
    const scale = useYScale();
    const y0 = scale(0);
    const off = y0 !== undefined ? y0 / svgHeight : 0;
    return (
      <defs>
        <linearGradient
          id={id}
          x1="0"
          x2="0"
          y1="0"
          y2={`${svgHeight}px`}
          gradientUnits="userSpaceOnUse"
        >
          <stop
            offset={off}
            stopColor={seriesColorGreen}
            stopOpacity={0.8}
          />
          <stop
            offset={off}
            stopColor={seriesColorRed}
            stopOpacity={0.8}
          />
        </linearGradient>
      </defs>
    );
  }

  useEffect(() => {
    fnsDashboard.getNetWorthData();
  },[filter]);
  
  useEffect(() => {
    fnsFilters.onDateFilterLoad();
  },[])

  return (
    <div className="filledChartContainer">
      {loader && (
        <LinearProgress
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
          }}
        />
      )}
      <Row justify={"between"} className="chart-header">
        <span className="chart-label">{label} *</span>
        <FormControl
          sx={{
            ".MuiInputBase-root": {
              "::before": {
                borderBottom: "none !important",
              },
              "::after": {
                borderBottom: "none !important",
              },
            },
            ".MuiInputBase-input": {
              backgroundColor: 'transparent'
            }
          }}
        >
          <Select variant="standard" value={dataFilters?.dateFilter?.length ? filter : ""} onChange={handleDropdown}>
            {dataFilters?.dateFilter?.map(d => <MenuItem key={d?.id} value={d?.id}>{d?.name}</MenuItem>)}
          </Select>
        </FormControl>
      </Row>
      <LineChart
        margin={{ top: 25, left: 60 }}
        sx={{
          width: "100%",
          paddingLeft: isMobile ? 1 : 0,
          [`& .${areaElementClasses.root}`]: {
            fill: "url(#swich-color-id-2)",
          },
          [`.${lineElementClasses.root}, .${markElementClasses.root}`]: {
            strokeWidth: 1,
          },
          ".MuiLineElement-root": {
            strokeDasharray: "2 2",
            stroke: "url(#swich-color-id-2)"
          },
        }}
        yAxis={[
          {
            valueFormatter: (value) => {
              if (value >= 1000000) {
                return `$${(value / 1000000).toFixed(1)}M`;
              } else if (value <= -1000000) {
                return `-$${Math.abs(value / 1000000).toFixed(1)}M`;
              } else if (value > 0) {
                return `$${Math.round(value / 1000)}k`;
              } else if (value < 0) {
                return `-$${Math.abs(Math.round(value / 1000))}k`;
              } else {
                return isNegativeData ? 0 : "";
              }
            },
          },
        ]}
        xAxis={[
          {
            scaleType: "point",
            data: data?.map((d) => formatDateString(d?.date)),
          },
        ]}
        series={[
          {
            curve: "linear",
            data: data?.map((d) => d?.amount),
            area: true,
            valueFormatter: (value) => !value ? "-" : usdFormate(value)?.slice(0, -1),
            label: `New Worth`,
            showMark: false,
            color: '#0c89ef'
          },
        ]}
        tooltip={{
          trigger: "axis",
        }}
        slots={{
          axisContent: CustomItemTooltipContent,
        }}
        slotProps={{
          legend: {
            hidden: true,
          },
        }}
        height={300}
      >
        <ColorSwitch
          id="swich-color-id-2"
        />
        {data?.length && <ChartsReferenceLine y={"0"} lineStyle={{height: "1px"}} />}
      </LineChart>
    </div>
  );
};

export default LineChartFilled;
