import React, { useEffect, useMemo, useRef, useState } from "react";
import MerchantRuleFields from "./MerchantRuleFields";
import KmnButton from "../Transactions/KmnButton";
import CategoryRuleFields from "./CategoryRuleFields";
import Tags from "../Transactions/Tags";
import ArrowDropUpRoundedIcon from "@mui/icons-material/ArrowDropUpRounded";
import useDeviceType from "src/hooks/useDeviceType";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import Loader from "src/components/shared/Loader";
import { attributeCode, operatorCode, RULE_MODE, RULES_ERROR_TYPE } from "./constants";
import { EditIcon, ErrorIcon } from "src/libs/icons";
import { checkHarmfulKeywords } from "src/utils/checkHarmfulKeywords";

const EditRule = ({ selectedForEdit, cancelEditOrCreateRule, ruleMode, data, fns }) => {
  const [merchantRuleFields, setMerchantRuleFields] = useState([
    {
      id: Date.now(),
      attribute: "",
      condition: "",
      value: "",
    },
  ]);
  const [categoryRuleFields, setCategoryRuleFields] = useState([{ id: Date.now(), attribute: "", value: "" }]);
  const [ruleTitle, setRuleTitle] = useState("New Rule");
  const [isInputFocused, setIsInputFocused] = useState(false);
  const inputRef = useRef(null);
  const { isMobile } = useDeviceType();
  const [isMerchantRuleFieldToggled, setIsMerchantRuleFieldToggled] = useState(true);
  const [isCategoryRuleFieldToggled, setIsCategoryRuleFieldToggled] = useState(true);
  const [selectedTags, setSelectedTags] = useState([]);
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const categoryFieldsMoreThan1 = categoryRuleFields?.length > 1;
  const isSaveEnable =
    !!merchantRuleFields[0]?.attribute &&
    !!merchantRuleFields[0]?.condition &&
    (typeof merchantRuleFields[0]?.value === "string" ? !!merchantRuleFields[0]?.value?.trim() : !!merchantRuleFields[0]?.value) &&
    ((!!categoryRuleFields[0]?.attribute && (typeof categoryRuleFields[0]?.value === "string" ? !!categoryRuleFields[0]?.value?.trim() : !!categoryRuleFields[0]?.value)) || selectedTags?.length);
  const merchantAttributeId = useMemo(() => data.ruleAttributes?.source.find(d => d?.code === attributeCode.MERCHANT)?.id, [data.ruleAttributes]);
  const [keywordsError, setKeywordsError] = useState("");

  useEffect(() => {
    if(isSaveEnable){
      let payload = {
        id: selectedForEdit?.ruleId,
        ruleName: ruleTitle,
        leftRules: merchantRuleFields,
        rightRules: categoryRuleFields,
        tags: selectedTags,
      };
      fns.getMatchingTransactionCountForRule(payload)
    }
  },[isSaveEnable, merchantRuleFields, categoryRuleFields, selectedTags])

  useEffect(() => {
    return () => fns.resetTransactionCount();
  },[])

  useEffect(() => {
    if (selectedForEdit) {
      setRuleTitle(selectedForEdit?.ruleName);
      setMerchantRuleFields(selectedForEdit?.conditions?.map((d) => ({ id: d?.conditionId, attribute: d?.attributeId, condition: d?.operatorId, value: d?.operatorCode === operatorCode.EQUAL ? +d?.attributeIdValue : d?.attributeIdValue })));
      if(selectedForEdit?.results?.length){
        setCategoryRuleFields(selectedForEdit?.results?.map(d => ({
          id: d?.resultId,
          attribute: d?.attributeId,
          value: +d?.attributeIdValue
        })))
      }else{
        setCategoryRuleFields([{ id: Date.now(), attribute: "", value: "" }]);
      }
      setSelectedTags(selectedForEdit?.resultsTags)
    }
  }, [selectedForEdit]);

  

  const addMerchantField = () => {
    if (merchantRuleFields?.length < 4) {
      setMerchantRuleFields((prev) => [...prev, { id: Date.now(), attribute: "", condition: "", value: "" }]);
    }
  };

  const removeMerchantField = (idx) => {
    let prevData = [...merchantRuleFields];
    prevData.splice(idx, 1);
    setMerchantRuleFields(prevData);
  };

  const addCategoryField = () => {
    if (categoryRuleFields?.length < 4) {
      setCategoryRuleFields((prev) => [...prev, { id: Date.now(), attribute: "", value: "" }]);
    }
  };

  const removeCategoryField = (idx) => {
    let prevData = [...categoryRuleFields];
    prevData.splice(idx, 1);
    setCategoryRuleFields(prevData);
  };

  const handleTitleChange = (e) => {
    setRuleTitle(e.target.value);
    resetError();
    fns.resetError();
  };

  const handleInputFocus = () => {
    setIsInputFocused(true);
    const input = inputRef.current;
    input.focus();
    input.setSelectionRange(input.value.length, input.value.length);
  };

  const resetTitle = (e) => {
    let value = e?.target?.value?.trim();
    if (value) {
      setRuleTitle(value);
    } else {
      setRuleTitle("New Rule");
    }
    setIsInputFocused(false);
  };

  const handleMerchantRuleFields = () => {
    setIsMerchantRuleFieldToggled(!isMerchantRuleFieldToggled);
  };

  const handleCategoryRuleFields = () => {
    setIsCategoryRuleFieldToggled(!isCategoryRuleFieldToggled);
  };

  const handleMerchantCondition = ({ name, value, index }) => {
    let modifiedData = [...merchantRuleFields];
    merchantRuleFields[index][name] = value;
    setMerchantRuleFields(modifiedData);
  };

  const handleCategoryCondition = ({ name, value, index }) => {
    let modifiedData = [...categoryRuleFields];
    categoryRuleFields[index][name] = value;
    setCategoryRuleFields(modifiedData);
  };
  
  const handleSave = async () => {
    
    let merchantValue = merchantRuleFields?.find(d => d?.attribute === merchantAttributeId)?.value;

    let error = checkHarmfulKeywords(merchantValue);
  
    if(error){
      return setKeywordsError(error)
    }

    let payload = {
      id: selectedForEdit?.ruleId,
      ruleName: ruleTitle,
      leftRules: merchantRuleFields,
      rightRules: categoryRuleFields,
      tags: selectedTags,
    };
    if (ruleMode === RULE_MODE.CREATE) {
      await fns.createRule(payload);
      cancelEditOrCreateRule();
    }else if(ruleMode === RULE_MODE.EDIT){
      await fns.editRule(payload);
      cancelEditOrCreateRule();
    }
    setIsSaveClicked(true);
    fns.resetError();
  };

  const resetError = () => {
    if (isSaveClicked) {
      setIsSaveClicked(false);
    }
  };

  const handleTagSelection = (tags) => {
    setSelectedTags(tags);
    fns.resetError();
  };

  const resetKeywordsError = () => {
    if(keywordsError){
      setKeywordsError("")
    }
  }

  return (
    <div className="editRuleBox">
      <Loader visible={data?.loading} />
      <div className="headTitle">
        <div className="headeTitleEditSection">
          <div className="headTitleCover">
            <span className="dummyTitle">
              {ruleTitle}
              <input type="text" value={ruleTitle} onBlur={resetTitle} onFocus={handleInputFocus} onChange={handleTitleChange} ref={inputRef} style={{ cursor: isInputFocused ? "text" : "pointer" }} />
            </span>
          </div>{" "}
          {!isInputFocused && <EditIcon onClick={handleInputFocus} className="editIcon" />}
        </div>

        {((data.error && data.errorType !== RULES_ERROR_TYPE.DELETE) || keywordsError) && (
          <div className="error">
            <ErrorIcon /> {keywordsError || data.error}
          </div>
        )}
      </div>
      <div className="merchantCategoryRule">
        <div className={"merchantRule addRule " + (isMerchantRuleFieldToggled ? "expanded" : "collapsed")}>
          <div className="title" onClick={handleMerchantRuleFields}>
            <span>IF following are TRUE</span>
            {isMobile && (isMerchantRuleFieldToggled ? <ArrowDropUpRoundedIcon className="dropdownIcon" /> : <ArrowDropDownRoundedIcon className="dropdownIcon" />)}
          </div>
          {(!isMobile || (isMobile && isMerchantRuleFieldToggled)) && (
            <div className="ruleFieldsContainer">
              {merchantRuleFields.map((d, idx) => (
                <React.Fragment key={d?.id}>
                  <MerchantRuleFields
                    merchantRuleFields={merchantRuleFields}
                    data={d}
                    addMerchantField={addMerchantField}
                    removeMerchantField={removeMerchantField}
                    index={idx}
                    attributes={data.ruleAttributes?.source}
                    handleMerchantCondition={handleMerchantCondition}
                    getRuleOperators={fns.getRuleOperators}
                    isSaveClicked={isSaveClicked}
                    resetError={resetError}
                    resetApiError={fns.resetError}
                    ruleMode={ruleMode}
                    keywordsError={keywordsError}
                    resetKeywordsError={resetKeywordsError}
                  />
                </React.Fragment>
              ))}
            </div>
          )}
        </div>
        <div className={"categoryRule addRule " + (isCategoryRuleFieldToggled ? "expanded" : "collapsed")}>
          <div className="title" onClick={handleCategoryRuleFields}>
            <span>Apply the following updates</span>
            {isMobile && (isCategoryRuleFieldToggled ? <ArrowDropUpRoundedIcon className="dropdownIcon" /> : <ArrowDropDownRoundedIcon className="dropdownIcon" />)}
          </div>
          {(!isMobile || (isMobile && isCategoryRuleFieldToggled)) && (
            <div className="ruleFieldsContainer">
              <div className={`ruleFields ${categoryFieldsMoreThan1 && "ruleFieldsExtended"}`}>
                {categoryRuleFields.map((d, idx) => (
                  <React.Fragment key={d?.id}>
                    <CategoryRuleFields
                      data={d}
                      key={idx}
                      index={idx}
                      categoryRuleFields={categoryRuleFields}
                      removeCategoryField={removeCategoryField}
                      attributes={data.ruleAttributes?.destination}
                      handleCategoryCondition={handleCategoryCondition}
                      isSaveClicked={isSaveClicked}
                      addCategoryField={addCategoryField}
                      resetError={resetError}
                      selectedTags={selectedTags}
                      resetApiError={fns.resetError}
                    />
                  </React.Fragment>
                ))}
              </div>
              <div className="rulesTags">
                <Tags rules={true} associatedTags={selectedTags} setTags={handleTagSelection} />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="editRuleActionsCover">
        {isSaveEnable && (data.matchingRuleTransactionCount !== null || data.matchingRuleTransactionCount !== undefined) ? <p className="ruleApplyLabel">Rule will be applied to {data.matchingRuleTransactionCount} transactions</p> : null}
        <div className="editRuleActions">
          <KmnButton variant="secondary" color="primary_outlined" onClick={cancelEditOrCreateRule}>
            Cancel
          </KmnButton>
          <KmnButton isDisabled={!isSaveEnable || data.loading} variant="secondary" color="primary" onClick={handleSave}>
            Save
          </KmnButton>
        </div>
        
      </div>
    </div>
  );
};

export default EditRule;
