import { Autocomplete, Box, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { atm_globalFilterValue } from "src/state/atoms";

const NewMerchantFilter = ({ data, callBackFunction }) => {
  const [checkedMerchants, setCheckedMerchants] = useState([]);
  const globalFilterValue = useRecoilValue(atm_globalFilterValue);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const updateLocalDataWithGlobalData = () => {
    const { includeMerchantIds, excludeMerchantIds } =
      globalFilterValue.merchantFilter;
    if (includeMerchantIds.length) {
      setCheckedMerchants(
        data.filter((d) => includeMerchantIds.includes(d.providerId))
      );
      return;
    } else if (excludeMerchantIds.length) {
      setCheckedMerchants(
        data.filter((d) => !excludeMerchantIds.includes(d.providerId))
      );
      return;
    } else {
      setCheckedMerchants([]);
    }
  };

  useEffect(() => {
    if (data.length) {
      updateLocalDataWithGlobalData();
    }
  }, [data]);

  const handleApply = (selections) => {
    let includedIds = [];
    let exludedIds = [];
    if (!selections?.length) {
      callBackFunction(includedIds, exludedIds);
    } else {
      includedIds = selections.map((d) => d.providerId);
      exludedIds = data
        .filter((d) => !includedIds.includes(d.providerId))
        ?.map((d) => d?.providerId);
      callBackFunction(includedIds, exludedIds);
    }

    setCheckedMerchants(selections);
  };

  return (
    <div
      className={`filterDropdown merchantFilter ${
        isMenuOpen && "filterFocused"
      }`}
    >
      <span className="label">Merchant</span>
      <Autocomplete
        onChange={(_, value) => handleApply(value)}
        multiple
        id="tags-outlined"
        options={data}
        value={checkedMerchants}
        getOptionLabel={(option) => option?.providerName}
        disableCloseOnSelect
        renderInput={(params) => (
          <TextField
            {...params}
            label={!checkedMerchants.length ? "All" : null}
          />
        )}
        renderOption={(props, option) => {
          const { key, ...optionProps } = props;

          return (
            <Box key={option?.providerId} component="li" {...optionProps}>
              {option?.providerName}
            </Box>
          );
        }}
        onOpen={() => {
          setIsMenuOpen(true);
        }}
        onClose={() => {
          setIsMenuOpen(false);
        }}
        size="small"
      />
    </div>
  );
};

export default NewMerchantFilter;
