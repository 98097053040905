export const attributeCode = {
    MERCHANT: "MERCH",
    CATEGORY: "CATG",
    AMOUNT: "AMNT",
    ACCOUNT: "ACCT"
}

export const operatorCode = {
    EQUAL: "EQL",
    GREATHER_THAN_EQUAL: "GTE",
    GREATER_THAN: "GT",
    LESS_THAN_EQUAL: "LTE"
}

export const RULE_MODE = {
    CREATE: "create",
    EDIT: "edit"
}

export const RULES_ERROR_TYPE = {
    CREATE: "create",
    EDIT: "edit",
    DELETE: "delete"
}