import {
  Autocomplete,
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import useDeviceType from "src/hooks/useDeviceType";
import Col from "./Col";

const KmnTextfield = ({
  autoComplete = false,
  groupKey = null,
  options = [],
  label = "",
  select = false,
  helperText = "",
  optionKey = "",
  optionValue = "",
  optionClassName = "",
  optionProps = {},
  customDropdown,
  customDropdownProps = {},
  maxWidth,
  hideOptions = false,
  additionalOption = null,
  popupIcon,
  slotProps,
  error = false,
  errorMessage = "",
  noOptionsText,
  onInputChange,
  filterOptions,
  onOpen,
  inputRef,
  inputProps,
  InputProps,
  ...otherProps
}) => {
  const ComponentContainer = select ? FormControl : Col;
  const Component = select ? Select : TextField;
  const { isMobile } = useDeviceType();
  const inputVariant = isMobile ? "standard" : "outlined";
  const inputSize = isMobile ? "small" : "medium";

  const helperStyle = { position: "absolute", bottom: -18, margin: 0, left: 1, fontSize: 10 };
  

  let optionsData = options?.map((d) => ({
    label: d?.[optionKey],
    value: d?.[optionValue],
    group: d?.[groupKey],
  }));
  
  if (autoComplete) {
    const value =
    options?.find((d) => d?.[optionValue] === otherProps?.value) || null;
    
    return (
      <Autocomplete
        {...otherProps}
        filterOptions={filterOptions}
        onInputChange={onInputChange}
        noOptionsText={noOptionsText}
        onOpen={onOpen}
        value={value}
        disablePortal
        options={options}
        groupBy={groupKey ? (option) => option?.[groupKey] : null}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            error={!!error}
            helperText={error ? errorMessage : helperText}
            sx={{
              ".MuiFormHelperText-root": helperStyle,
            }}
          />
        )}
        getOptionLabel={(option) => option?.[optionKey]}
        slotProps={slotProps}
        renderGroup={(params) =>
          groupKey ? (
            <li key={params.key}>
              <div style={{ paddingLeft: "1rem", fontWeight: 700 }}>
                {params.group}
              </div>
              <div style={{ paddingLeft: "1rem", paddingRight: "1rem" }}>
                {params.children}
              </div>
            </li>
          ) : null
        }
        popupIcon={popupIcon}
        renderOption={(props, option) => {
          const isAdditionOption =
            props?.["data-option-index"] === options.length - 1;

          const { key, ...optionProps } = props;
          return (
            <React.Fragment key={option?.[optionValue]}>
              <Box
                sx={{
                  display:
                    hideOptions &&
                    value?.[optionValue] !== option?.[optionValue]
                      ? "none !important"
                      : "block",
                  minHeight: isMobile ? 'min-content !important' : undefined,
                  fontSize: isMobile ? 12 : undefined
                }}
                title={otherProps?.name}
                value={option?.[optionValue]}
                key={option?.[optionValue]}
                component="li"
                {...optionProps}
              >
                {option?.[optionKey]}
              </Box>
              {isAdditionOption ? additionalOption : null}
            </React.Fragment>
          );
        }}
      />
    );
  }

  const componentProps = select ? { onOpen, inputRef } : { helperText: error ? errorMessage : helperText, select, InputProps };
  
  return (
    <ComponentContainer
      error={!!error}
      fullWidth
      style={{
        maxWidth,
        width: "100%",
      }}
      sx={{
        maxWidth,
      }}
      className={`${componentProps?.className} ${select ? "select" : 'textField'}`}
    >
      {select && <InputLabel>{label}</InputLabel>}
      <Component
        {...componentProps}
        value={componentProps?.value || ""}
        error={!!error}
        label={label}
        variant={inputVariant}
        size={inputSize}
        inputProps={inputProps}
        sx={{
          ".MuiFormHelperText-root": helperStyle,
        }}
        {...otherProps}
      >
        {select &&
          optionsData?.map((d) => {
            const isHidden =
              typeof optionProps?.hidden === "function"
                ? optionProps.hidden(d)
                : false;

            if (groupKey && d?.group) {
              return (
                <ListSubheader
                  sx={{
                    fontSize: "1rem",
                    fontWeight: 700,
                    height: "36px",
                    color: "#000",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {d?.group}
                </ListSubheader>
              );
            }

            return (
              <MenuItem
                className={`${optionClassName} kmnTextfieldOption`}
                key={d?.value}
                value={d?.value}
                style={{
                  display: isHidden || customDropdown ? "none" : "block",
                  paddingLeft: groupKey ? "2rem" : undefined,
                }}
              >
                {d?.label}
              </MenuItem>
            );
          })}
      </Component>
      {error && select && (
        <FormHelperText sx={helperStyle}>{errorMessage}</FormHelperText>
      )}
    </ComponentContainer>
  );
};

export default KmnTextfield;
