import React from "react";
import { Tooltip, useAutocomplete } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/material/styles";
import { autocompleteClasses } from "@mui/material/Autocomplete";
import { useRecoilValue, useSetRecoilState } from "recoil";
import {
  atm_isManageTagsModal,
  atm_transactionTags,
} from "src/state/atomsTransactionData";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import useTransactionsLanding from "./useTransactionsLanding";
import Loader from "src/components/shared/Loader";
import useDeviceType from "src/hooks/useDeviceType";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";

const Root = styled("div")(
  ({ theme }) => `
  color: ${
    theme.palette.mode === "dark" ? "rgba(255,255,255,0.65)" : "rgba(0,0,0,.85)"
  };
  font-size: 14px;
`
);

const Label = styled("label")`
  padding: 0 0 4px;
  line-height: 1.5;
  display: block;
  position: absolute;
  left: 8px;
  top: 8px;
`;

const InputWrapper = styled("div")(
  ({ theme }) => `
  padding: 5px;
  min-width: 50px;
  width: min-content;
  border-radius: 4px;
  display: flex;
  flex-wrap: wrap;
  background: current;
  position: relative;
  gap: 4px;

  &.focused {
    width: 200px;
    background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
    border: 1px solid rgb(0 0 0/0.23);
    .label{
      top: -2px;
      transform: translateY(-50%) scale(0.8);
      transition: 0.2s;
      background: white;
      padding: 0 5px;
    }
  }

  &.not-focused {
    height: 40px;
    overflow: hidden;
    cursor: default;
    input {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      cursor: pointer;
    }
  }

  & input {
    background: transparent;
    position: relative;
    height: 30px;
    padding: 4px 6px;
    flex-grow: 1;
    border: 0;
    margin: 0;
    outline: 0;
  }
    
  input[type="text"] {
    font-size: 16px;
    line-height: 26.666666667px;
    padding: 6.666666667px;
    width: 133.333333333%;

    transform: scale(0.75);
    transform-origin: left top;
    margin-bottom: -10px;
    margin-right: -33.333333333%;
  }
`
);

function Tag(props) {
  const { key, id, label, focused, associatedTag, onDelete, ...other } = props;
  
  const handleDelete = () => {
    if (focused) {
      associatedTag();
    }
  };
  return (
    <div key={key} {...other} onClick={handleDelete}>
      <span>{label}</span>
      {focused && <CloseIcon />}
    </div>
  );
}

const StyledTag = styled(Tag)(
  ({ theme }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  width: 60px;
  margin: 2px;
  overflow-text: ellipse;
  line-height: 22px;
  cursor: pointer;
  background-color: ${
    theme.palette.mode === "dark" ? "rgba(255,255,255,0.08)" : "#fafafa"
  };
  border: 1px solid ${theme.palette.mode === "dark" ? "#303030" : "#e8e8e8"};
  border-radius: 2px;
  box-sizing: content-box;
  padding: 0 6px;
  outline: 0;
  position: relative;
  &:focus {
    border-color: ${theme.palette.mode === "dark" ? "#177ddc" : "#40a9ff"};
    background-color: ${theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"};
  }

  & span {
    font-size: 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  & svg {
    font-size: 10px;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(50%,-50%)
  }
`
);

const Listbox = styled("ul")(
  ({ theme }) => `
  width: 200px;
  margin: 2px 0 0;
  padding: 0;
  position: relative;
  // z-index: 100;
  list-style: none;
  background-color: ${theme.palette.mode === "dark" ? "#141414" : "#fff"};
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1;

  & .listbox-inside{
    padding-top: 6px;
    overflow: auto;
    max-height: 160px;
  }
  & li {
    padding: 5px 12px;
    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    font-size: 12px;

    &:hover {
      background: rgba(0,0,0,0.03);
    }

    & span {
      flex-grow: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      color: black;
    }

    & svg {
      color: transparent;
    }
  }

  & li[aria-selected='true'] {
    background-color: ${theme.palette.mode === "dark" ? "#2b2b2b" : "#fafafa"};
    font-weight: 600;

    & svg {
      color: #1890ff;
    }
  }

  & li.${autocompleteClasses.focused} {
    background-color: ${theme.palette.mode === "dark" ? "#003b57" : "#e6f7ff"};
    cursor: pointer;

    & svg {
      color: currentColor;
    }
  }
`
);

export default function Tags({ isPending, transactionId, associatedTags, rules, setTags }) {
  const tagsData = useRecoilValue(atm_transactionTags);
  const { dataTransactions, fnsTransactions } = useTransactionsLanding();

  const handleTagAssocation = (tagOption) => {
    let tagId = tagOption?.tagId;
    let isTagAlreadyExist = associatedTags.some((d) => d?.tagId === tagId);
    
    if (rules) {
      setTags((prevTags) => {
        if(isTagAlreadyExist){
          return prevTags.filter(d => d?.tagId !== tagId)
        }
        return [...prevTags, tagOption]
      })
    }else{
      if (isTagAlreadyExist) {
        fnsTransactions.removeTagFromTransaction(transactionId, tagId);
      } else {
        fnsTransactions.associateTagWithTransaction(transactionId, tagId);
      }
    }
  };

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getOptionProps,
    value,
    focused,
  } = useAutocomplete({
    id: "custom-tags-dropdown",
    multiple: true,
    options: tagsData || [],
    getOptionLabel: (option) => option?.tagName,
    disableCloseOnSelect: true,
    disabled: isPending || dataTransactions.loading,
    freeSolo: true,
    value: associatedTags || [],
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [isPreviewTags, setIsPreviewTags] = useState(false);
  const [isTagsDropdown, setIsTagsDropdown] = useState(false);
  const [isWrapped, setIsWrapped] = useState(false);
  const { isMobile } = useDeviceType();
  const associatedIds = associatedTags?.map((d) => d?.tagId);
  const tagsDropdownRef = useRef(null);
  const inputWrapperRef = useRef(null);
  const setIsManageTagsModal = useSetRecoilState(atm_isManageTagsModal);
  const filteredTags = dataTransactions?.transactionTags?.filter((d) =>
    d?.tagName.toLowerCase()?.startsWith(searchQuery?.trim()?.toLowerCase())
  );
  const handleCreateNewTag = () => {
    fnsTransactions.addTransactionTag(searchQuery, transactionId);
  };

  const handlePreviewTagsClose = () => {
    setIsPreviewTags(false);
  };

  const handleTagsDropdownOpen = () => {
    const { ref: tagInputRef } = getInputProps();
    setIsTagsDropdown(true);
    setIsPreviewTags(false);
    tagInputRef?.current?.focus();
  };

  const handlePreviewTagsOpen = () => {
    setIsPreviewTags(true);
  };

  const handleTagsDropdownClose = () => {
    setIsTagsDropdown(false);
    setSearchQuery("");
  };

  const handleManageTagsModal = () => {
    setIsManageTagsModal(true);
    handleTagsDropdownClose();
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        !tagsDropdownRef?.current?.contains(event.target) &&
        !inputWrapperRef?.current?.contains(event.target)
      ) {
        handleTagsDropdownClose();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputWrapperRef, tagsDropdownRef]);

  useEffect(() => {
    const checkIfWrapped = () => {
      if (inputWrapperRef.current) {
        const parentElement = inputWrapperRef.current.parentElement;
        const tags = inputWrapperRef.current.querySelectorAll('.tag');
        let totalTagsWidth = 0;
        tags.forEach(tag => {
          totalTagsWidth += tag.offsetWidth;
        });
  
        const isNowWrapped = totalTagsWidth > parentElement.clientWidth;
        setIsWrapped(isNowWrapped);
      }
    };
  
    checkIfWrapped();
    window.addEventListener("resize", checkIfWrapped);
    return () => {
      window.removeEventListener("resize", checkIfWrapped);
    };
  }, [associatedTags]);
  

  return (
    <Root className={`tags-box ${focused ? 'focused-tags-box' : 'not-focused-tags-box'} ${!value.length && 'tags-box-empty'} `}  {...getRootProps()}>
      <Tooltip
        ref={inputWrapperRef}
        open={isPreviewTags || isTagsDropdown}
        onOpen={() => value?.length > 0 && !focused && handlePreviewTagsOpen()}
        onClose={handlePreviewTagsClose}
        placement={"bottom-start"}
        title={
          isPreviewTags ? (
            <div
              className="previewTags"
              sx={{
                ".MuiTooltip-tooltip": {
                  background: "transparent !important",
                },
              }}
              onClick={handleTagsDropdownOpen}
            >
              {associatedTags.map((d) => (
                <div key={d?.tagId} className="previewTag">
                  {d?.tagName}
                </div>
              ))}
            </div>
          ) : isTagsDropdown ? (
            <Listbox ref={tagsDropdownRef} className="tagsListbox">
              <Loader visible={dataTransactions.loading} />
              <div className="listbox-inside">
                {filteredTags.map((option, index) => {
                  const { ...optionProps } = getOptionProps({ option, index });
                  return (
                    <li
                      {...optionProps}
                      key={option?.tagId}
                      className={
                        associatedIds?.includes(option?.tagId)
                          ? "selected"
                          : undefined
                      }
                      onClick={() => handleTagAssocation(option)}
                      value={option?.tagId}
                    >
                      <span>{option?.tagName}</span>
                    </li>
                  );
                })}
              </div>
              <div className="btns">
                {searchQuery && !filteredTags.length && (
                  <>
                    <span className="no-tags-label">No Tags found</span>
                    <button
                      className="createBtn"
                      onClick={handleCreateNewTag}
                      disabled={dataTransactions.loading}
                    >
                      Create Tag “{searchQuery}”
                    </button>
                  </>
                )}
                <>
                  <hr />
                  <button className="manageBtn" onClick={handleManageTagsModal}>
                    Manage Tags
                  </button>
                </>
              </div>
            </Listbox>
          ) : null
        }
        componentsProps={{
          tooltip: {
            sx: {
              backgroundColor: "transparent",
              padding: 0,
              maxWidth:'unset',
              width: isMobile ? `calc(100vw - 118px)` : undefined,
            },
          },
        }}
        slotProps={{
          popper: {
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: isMobile ? [0, -16] : [0, -12],
                },
              },
            ],
          },
        }}
      >
        <div
          className={!value.length && !isTagsDropdown && !rules ? "no-tags" : undefined}
          onClick={handleTagsDropdownOpen}
        >
          <InputWrapper
            className={`input-wrapper ${
              isTagsDropdown ? "focused" : "not-focused"
            }`}
            sx={{
              width: value.length > 1 ? 174 : undefined,
              border: !value.length ? "1px solid rgb(0 0 0/0.23)" : undefined,
            }}
          >
            <Label className="label" {...getInputLabelProps()}>
              Tags
            </Label>
            {value.map((option, index) => {
              const { key ,...tagProps } = getTagProps({ index });
              return (
                <StyledTag
                  key={option?.tagId}
                  id={option?.tagId}
                  {...tagProps}
                  focused={isTagsDropdown}
                  label={option?.tagName}
                  associatedTag={() => handleTagAssocation(option)}
                  className="tag"
                />
              );
            })}
            {isTagsDropdown && <ArrowDropUpIcon className="arrow-icon" />}
            <input
              readOnly={!isTagsDropdown}
              type="text"
              maxLength={24}
              {...getInputProps()}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </InputWrapper>
          {isMobile && !isTagsDropdown && associatedTags?.length > 0 && (
            <ArrowDropDownIcon className="arrow-icon mob-notFocused-arrowIcon" />
          )}
        {((rules && associatedTags?.length > 2 && !isTagsDropdown) || (isWrapped && !isTagsDropdown)) && (
            <div className="dot">...</div>
          )}
          {!rules && !value.length && !isTagsDropdown && !isPending && (
            <div className="add-btn" onClick={handleTagsDropdownOpen}>
              <AddCircleIcon className="add-icon" /> Tag
            </div>
          )}
        </div>
      </Tooltip>
    </Root>
  );
}
