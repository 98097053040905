import {
  Box,
  LinearProgress,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import React, { forwardRef, useEffect, useRef, useState } from "react";
import { Col, Row, Text } from "src/components/shared";
import {
  dateFormateGoals,
  monthNames,
  usdFormate,
  usdUnformat,
} from "src/global/central";
import CloseIcon from "../../assets/images/close.svg";
import DoneIcon from "../../assets/images/tick2.svg";
import PencilIcon from "../../assets/images/pencil2.svg";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  // atm_accounts,
  atm_editAccountModal,
  atm_selectedAccountType,
  atm_selectedEditAccount,
} from "src/state/atomsAccountList";
import useEditAccount from "./useEditAccount";
import KmnButton from "../Transactions/KmnButton";
// import useAccountList from "../useAccountList";
import { NumericFormat } from "react-number-format";
import KmnTextfield from "src/components/shared/KmnTextfield";
import useAddManualAccount from "../AddManualAccount/useAddManualAccount";
import useDeviceType from "src/hooks/useDeviceType";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

const EditAccountModal = ({ handleTotal }) => {
  // const { data: dataAccount } = useAccountList();
  const { isMobile } = useDeviceType();
  const { data, fns } = useEditAccount();
  const [isRemoveClicked, setIsRemoveClicked] = useState(false);
  const [editAccountModal, setEditAccountModal] =
    useRecoilState(atm_editAccountModal);
  const selectedEditAccount = useRecoilValue(atm_selectedEditAccount);
  const selectedEditAccountType = useRecoilValue(atm_selectedAccountType);
  const days = Array.from({ length: 31 }, (_, index) => index + 1);
  const isManualAccount = selectedEditAccount.isManual;
  let accountBalance = Math.abs(selectedEditAccount?.accountBalance)?.toFixed(
    2
  );
  const [editedData, setEditedData] = useState({
    isManual: isManualAccount,
    accountType: isManualAccount
      ? selectedEditAccount.accountTypeId
      : selectedEditAccountType,
    accountBalance: accountBalance,
    accountId: selectedEditAccount.accountId || "",
    overrideAccountSubTypeId: selectedEditAccount.accountSubTypeId || "",
    overrideAccountName: selectedEditAccount.accountName,
    isAccountClosed: false,
    accountCloseDay: new Date().getDate(),
    accountCloseMonth: new Date().getMonth() + 1,
    accountCloseYear: new Date().getFullYear(),
  });
  
  const { data: addManualAccountData } = useAddManualAccount();
  const accountTypes = addManualAccountData.allAccountsData;
  const balanceRef = useRef(null);
  const [balance, setBalance] = useState(accountBalance);
  // const isLoading =
  // data.editAccountLoading || dataAccount.tokenForExistingAccountLoading;
  const handleFieldChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if(name === "accountType"){
      setEditedData((prevValues) => ({ ...prevValues, [name]: value, overrideAccountSubTypeId: "" }));
    }else{
      setEditedData((prevValues) => ({ ...prevValues, [name]: value}))
    }
  };

  const NumericFormatCustom = forwardRef(function NumericFormatCustom(
    props,
    ref
  ) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
          balanceRef.current.innerText = usdFormate(values.value)
        }}
        aria-valuemin={0}
        allowNegative={false}
        thousandSeparator
        valueIsNumericString
        prefix={"$ "}
        onBlur={(e) => {
          let value = e.target.value || 0;
          let floatedValue = usdUnformat(value).toFixed(2);
          e.target.value = usdFormate(+floatedValue);
          setBalance(floatedValue);
        }}
      />
    );
  });

  const handleNo = () => {
    setIsRemoveClicked(false);
  };

  const textFieldVariant = isMobile ? "standard" : "outlined";

  const handleModalClose = () => {
    if (data.editAccountLoading || data.accountDetailsLoading) {
      return;
    }
    setEditAccountModal(false);
  };

  useEffect(() => {
    fns.getAccountSubTypes(
      isManualAccount
        ? editedData.accountType
        : selectedEditAccount.accountTypeId
    );
  }, [editedData.accountType]);

  const handleRemoveAccount = async (id) => {
    await fns.removeAccount(id, isManualAccount);
    handleTotal();
    setEditAccountModal(false);
  };

  const handleSave = () => {
    fns.saveEditAccountDetails(editedData);
  };

  // const onSuccess = () => {
  //   fnsAccount.refreshAccountBalanceDataFromProvider(true);
  // };

  useEffect(() => {
    handleFieldChange({
      target: {
        value: balance,
        name: "accountBalance",
      },
    });
  }, [balance]);


  const renderAccountNameTextField = () => {
    return (<TextField
        variant={textFieldVariant}
        onChange={handleFieldChange}
        name="overrideAccountName"
        id="accountName"
        label="Account Name"
        onInput={(e) => {
        e.target.value = e.target.value.replace(
          /[^a-zA-Z0-9\s&-]/g,
          ""
        );
        }}
        defaultValue={selectedEditAccount?.accountName}
        InputProps={{
          endAdornment: isMobile ? (
            <img className="pencilIcon" src={PencilIcon} alt="" />
          ) : null,
        }}
        sx={{ gridColumn: "1 / span 2" }}
    />)
  }

  return (
    <Modal open={editAccountModal} onClose={handleModalClose}>
      <Box className="edit-account-modal">
        <Col className="head">
          <Text b size={24} className="text1">
            Edit {isManualAccount ? "Manual" : "Linked"} Account
          </Text>
          <Text size={18} className="text2">
            Update your {isManualAccount ? "manual" : "linked"} account details
          </Text>
          {!isMobile && (
            <img
              onClick={() => setEditAccountModal(false)}
              className="close-icon"
              src={CloseIcon}
              alt="close"
            />
          )}
        </Col>
        <Row className="edit-acccount-content">
          <Col className="left-content" justify={"between"}>
            {isMobile && data.isLoading && (
              <LinearProgress
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                }}
              />
            )}
            {isMobile && <div className="accountName">
              {renderAccountNameTextField()}
            </div>}
            <Col gap={20} className="account-details">
              {!isManualAccount && <Text b size={22} className="bank-name">
                  {selectedEditAccount?.accountFinInstitution}
              </Text>}
              <Col className="balance-as-of">
                <Row justify={"between"} className="row1">
                  <Text size={20} weight={500} className="heading">
                    {isManualAccount ? "Value" : "Balance"}
                  </Text>
                  <div className="balanceField">
                    <span
                      className="dummy"
                      style={
                        isManualAccount
                          ? {
                              opacity: 0,
                            }
                          : undefined
                      }
                      ref={balanceRef}
                    >
                      {usdFormate(balance)}
                    </span>
                    {isManualAccount && (
                      <>
                        <TextField
                          id="editAccountBalance"
                          variant="standard"
                          sx={{
                            "& .MuiInputBase-root": {
                              ":before": {
                                borderBottom: "none !important",
                              },
                              ":after": {
                                borderBottom: "none !important",
                              },
                            },
                          }}
                          name="accountBalance"
                          value={balance}
                          onBlur={handleFieldChange}
                          InputProps={{
                            inputComponent: NumericFormatCustom,
                          }}
                        />
                        <ModeEditIcon className="editIcon" />
                      </>
                    )}
                  </div>
                </Row>
                <Row justify={"between"} className="row2">
                  <Text size={14}>As Of</Text>
                  <Text size={14}>
                    {dateFormateGoals(
                      selectedEditAccount?.accountBalanceTimestamp
                    )}
                  </Text>
                </Row>
              </Col>
              <Row justify={"between"} align={"center"} className="date-linked">
                <Text size={20} weight={500}>
                  Date {isManualAccount ? "Added" : "Linked"}
                </Text>
                <Text weight={600}>
                  {dateFormateGoals(selectedEditAccount?.createDate)}
                </Text>
              </Row>
            </Col>
            {!isMobile && (
              <div
                className="btns"
                style={{ display: "flex", justifyContent: "center" }}
              >
                <KmnButton
                  variant="primary_free"
                  color="danger"
                  onClick={() => setIsRemoveClicked(true)}
                  style={{ width: "225px" }}
                >
                  <Text b size={18}>
                    Remove Account
                  </Text>
                </KmnButton>
                {/* <PlaidLinkButton
                  variant="primary_free"
                  color="primary"
                  getToken={() => fnsAccount.getAccessTokenForExistingAccount(selectedEditAccount.accountId)}
                  onSuccess={onSuccess}
                >
                  <Text b size={18}>
                    Link additional accounts
                  </Text>
                </PlaidLinkButton> */}
              </div>
            )}
          </Col>
          <Col
            className="right-content"
            justify={"between"}
            gap={
              editedData.isAccountClosed || isRemoveClicked || !isMobile
                ? 0
                : 30
            }
          >
            {!isMobile && data.isLoading && (
              <LinearProgress
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                }}
              />
            )}
            <div className="right-content-inside">
              {!isMobile && renderAccountNameTextField()}

              <KmnTextfield
                select={isManualAccount}
                variant={"standard"}
                onChange={handleFieldChange}
                name="accountType"
                id="accountType"
                label="Account Type"
                value={accountTypes?.length ? editedData.accountType : ""}
                sx={{
                  width: isMobile ? "100%" : 150,
                  ".MuiInputBase-input": {
                    paddingLeft: isMobile ? 0 : "5px",
                    textTransform: "capitalize",
                  },
                  ".MuiFormLabel-root": {
                    paddingLeft: isMobile ? 0 : "8px",
                  },
                }}
                options={accountTypes}
                optionKey={"accountType"}
                optionValue={"id"}
                InputProps={{ readOnly: !isManualAccount }}
              />
              <TextField
                variant={textFieldVariant}
                onChange={handleFieldChange}
                name="overrideAccountSubTypeId"
                id="accountSubType"
                label="Account Sub-Type"
                select
                value={data.accountSubTypes?.find(d => d?.id === editedData.overrideAccountSubTypeId)?.id || ""}
                fullWidth={isMobile}
              >
                {data.accountSubTypes.map((values) => (
                  <MenuItem key={values.id} value={values.id}>{values.accountSubType}</MenuItem>
                ))}
              </TextField>

            </div>
            <Row
              className={
                `mark-closed ${!editedData.isAccountClosed && "mark-closed-unchecked"} ${(isRemoveClicked ? "account-close-disabled" : "")}`}
              align={"center"}
              gap={15}
            >
              <input
                hidden
                name="isAccountClosed"
                type="checkbox"
                id="Mark-Closed"
                defaultChecked={editedData.isAccountClosed}
                onChange={(e) =>
                  setEditedData({
                    ...editedData,
                    isAccountClosed: e.target.checked,
                  })
                }
                disabled={isRemoveClicked}
              />
              <label className={"checkbox"} htmlFor="Mark-Closed">
                <img src={DoneIcon} alt="" />
              </label>
              <label className="text" htmlFor="Mark-Closed">
                Mark Closed
              </label>
              {editedData.isAccountClosed && (
                <div className="close-fields">
                  <div className="label">Closed by</div>
                  <div className="field" style={{ position: "relative" }}>
                    {!editedData.accountCloseDay && (
                      <label
                        id={`date-label`}
                        style={{
                          fontSize: "var(--fontSize13)",
                          color: "gray",
                        }}
                      >
                        DD
                      </label>
                    )}
                    <Select
                      labelId={`date-label`}
                      required
                      name="accountCloseDay"
                      defaultValue={editedData.accountCloseDay}
                      onChange={handleFieldChange}
                      style={{
                        color: "black",
                        fontSize: 13,
                        textAlign: "center",
                      }}
                      sx={{ width: 45 }}
                      variant="standard"
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200, // Adjust the max height as needed
                          },
                        },
                      }}
                    >
                      {days.map((day, index) => (
                        <MenuItem key={index} value={index + 1}>
                          {day}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>

                  <div className="field" style={{ position: "relative" }}>
                    {!editedData.accountCloseMonth && (
                      <label
                        id={`month-label`}
                        style={{
                          fontSize: "var(--fontSize13)",
                          color: "gray",
                        }}
                      >
                        MM
                      </label>
                    )}
                    <Select
                      className="monthField"
                      labelId={`month-label`}
                      name="accountCloseMonth"
                      defaultValue={editedData.accountCloseMonth}
                      onChange={handleFieldChange}
                      disabled={data.profileLoading}
                      sx={{ width: 50, fontSize: 13, textAlign: "center" }}
                      variant="standard"
                      required
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 200, // Adjust the max height as needed
                          },
                        },
                      }}
                    >
                      {monthNames.map((month, index) => (
                        <MenuItem key={index} value={index + 1}>
                          {month}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>

                  <TextField
                    variant="standard"
                    type="tel"
                    size="small"
                    name="accountCloseYear"
                    className="yearField"
                    defaultValue={editedData.accountCloseYear}
                    onChange={handleFieldChange}
                    sx={{
                      width: 45,
                      ".MuiInputBase-root": {
                        fontSize: 12,
                        position: "relative",
                        top: 2,
                        marginLeft: 0.5,
                      },
                      ".MuiFormLabel-root": {
                        fontSize: 12,
                      },
                    }}
                    inputProps={{ maxLength: 4, placeholder: "YYYY" }}
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[^0-9]/g, "");
                    }}
                  />
                </div>
              )}
            </Row>
            {editedData.isAccountClosed && !isRemoveClicked && (
              <>
                <div className="warning">
                  <p className="title">
                    Are you sure you want to mark this account "<b>Closed</b>" ?
                  </p>
                  <p className="desc">
                    This stops importing new data for this account, but keeps
                    its historic data and transactions. The account balance will
                    be $0 as of the date you select and any transactions after
                    that date will be removed. The account will be only be
                    visible if you view a time period before this date
                  </p>
                </div>
              </>
            )}
            {isRemoveClicked && (
              <div className="warning">
                <p className="title">
                  Are you sure you want to <b>delete</b> this account ?
                </p>
                <p className="desc">
                  This will remove all transactions and historic data for this
                  account. It will likely affect your aggregated balances, such
                  as past net worth, and historic spending totals. If you want
                  to keep the historic data, you can mark the account as closed.
                </p>
              </div>
            )}
            <Row gap={12} className={"btns " + (isRemoveClicked ? "removeAccountBtns" : undefined)}>
              {isRemoveClicked ? (
                <Row className="removeAccountBtnsInside">
                  <KmnButton
                    variant={isMobile ? "secondary" : "primary"}
                    isDisabled={data.isRemoveAccountLoading}
                    onClick={handleNo}
                  >
                    No
                  </KmnButton>
                  <KmnButton
                    variant={isMobile ? "secondary" : "primary"}
                    isDisabled={data.isRemoveAccountLoading}
                    color="danger"
                    onClick={() =>
                      handleRemoveAccount(selectedEditAccount?.accountId)
                    }
                  >
                    Yes, Remove
                  </KmnButton>
                </Row>
              ) : (
                <>
                  {isMobile && <KmnButton variant="secondary" color="danger" className="removeAccountBtn"
                      onClick={() => setIsRemoveClicked(true)}
                    >
                      Remove Account
                  </KmnButton>}
                  <Row gap={isMobile ? 6 : 12}>
                    <KmnButton
                      variant={isMobile ? "secondary_small" : "primary"}
                      color="primary_outlined"
                      onClick={() => setEditAccountModal(false)}
                      isDisabled={data.isLoading}
                    >
                      Cancel
                    </KmnButton>
                    <KmnButton
                      variant={isMobile ? "secondary_small" : "primary"}
                      color="primary"
                      onClick={handleSave}
                      isDisabled={data.accountDetailsLoading || data.isLoading}
                    >
                      Save
                    </KmnButton>
                  </Row>
                </>
              )}
            </Row>
          </Col>
        </Row>
      </Box>
    </Modal>
  );
};

export default EditAccountModal;
