import React from "react";
import { DataGrid } from "@mui/x-data-grid";
import LinearProgress from "@mui/material/LinearProgress";
import FiltersMob from "../../assets/images/filters-mob.svg";

import { useRecoilValue, useSetRecoilState } from "recoil";
import { usdFormate } from "src/global/central";
import { ArrowRightRounded } from "@mui/icons-material";
import { Box, Rating } from "@mui/material";
import useSpendByMerchant from "src/components/Insights/SpendByMerchant/useSpendByMerchant";
import {
  atm_InsightsMerchantList,
  atm_IsMobMerchantFilter,
  atm_globalMerchant,
} from "src/state/insightsMerchants";

const SidebarMerchant = () => {
  const { data, fns } = useSpendByMerchant();
  const merchantList = useRecoilValue(atm_InsightsMerchantList);

  const globalSelectedMerchant = useRecoilValue(atm_globalMerchant);

  const setIsMobFilter = useSetRecoilState(atm_IsMobMerchantFilter);

  const merchantListRows = merchantList.map((merchantData, index) => ({
    index: index,
    id: merchantData.hqMerchantId,
    col1: index + 1,
    col2: {
      logo: merchantData.merchantURL,
      merchantName: merchantData.merchantName,
      rating: merchantData.avgUserRatings,
      ratingCount: merchantData.totalUserWithRatings,
    },
    col3: {
      price: merchantData.txnTotal,
      percentage: merchantData.spendPercentage,
    },
  }));
  
  const merchantListColumns = [
    {
      field: "col1",
      headerClassName: "insight-grid-head",
      headerName: "#",
      width: "50",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "col2",
      headerClassName: "insight-grid-head",
      headerName: "MERCHANT",
      flex: 1,
      headerAlign: "left",
      cellClassName: "category-cell",
      valueGetter: (value) => value?.merchantName,
      renderCell: (params) => (
        <Box
          sx={{ display: "flex", alignItems: "center", gap: 1.5 }}
          className="custom-div"
        >
          {params.row.col2.logo && (
            <img
              height={30}
              width={30}
              srcSet={params.row.col2.logo}
              alt=""
            />
          )}
          <div>
            <div className="category">{params.row.col2.merchantName}</div>
            <div className="rating">
              <Rating
                name="read-only"
                value={params.row.col2.rating}
                precision={0.5}
                readOnly
              />{" "}
              <div className="rating-count">
                {"(" + params.row.col2.ratingCount + ")"}
              </div>
            </div>
          </div>
        </Box>
      ),
    },
    {
      minWidth: 165,
      field: "col3",
      headerClassName: "insight-grid-head total-spend-head",
      headerName: "TOTAL SPEND",
      headerAlign: "right",
      align: "right",
      cellClassName: "amount-cell",
      valueGetter: (value) => value?.price,
      renderCell: (params) => (
        <div className="custom-div">
          <div className="price" style={{ whiteSpace: "nowrap" }}>
            {usdFormate(params.row.col3.price)}
          </div>
          <div className="percentage" style={{ textAlign: "end" }}>
            {`${Math.round(params.row.col3.percentage)}%`}
            <ArrowRightRounded />
          </div>
        </div>
      ),
    },
  ];

  const getRowSpacing = ({ isFirstVisible, isLastVisible }) => ({
    top: isFirstVisible ? 0 : 4,
    bottom: isLastVisible ? 0 : 4,
  });

  return (
    <div
      className="merchant-sidebar-cover sidebar-cover"
      style={{ maxWidth: "100%", width: 700 }}
    >
      <div className="merchant-sidebar sidebar">
        {data.merchantListLoading && (
          <LinearProgress
            style={{ width: "100%", position: "absolute", top: 0, left: 0 }}
          />
        )}
        <header className="category-list-header mobile">
          <span>Insights - Spend by Merchant</span>
          <div className="filter-cover">
            <img
              onClick={() => {
                setIsMobFilter(true);
              }}
              src={FiltersMob}
              alt=""
            />
          </div>
        </header>
        <DataGrid
          getRowSpacing={getRowSpacing}
          rows={merchantListRows}
          columns={merchantListColumns}
          getRowHeight={() => "auto"}
          onRowClick={fns.onMerchantSelected}
          rowSelectionModel={globalSelectedMerchant ? [globalSelectedMerchant] : []}
          hideFooterSelectedRowCount
          pagination
          hideFooter={merchantListRows?.length <= 100}
        />
        <div className="total">
          <div className="text">Total</div>
          <div className="price">{usdFormate(merchantList[0]?.userTxnTotal || 0)}</div>
        </div>
      </div>
    </div>
  );
};

export default SidebarMerchant;
