import { useState, useCallback } from "react";
import axios from "axios";
import { getHeaders } from "./central";

const useApi = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const resetData = () => {
    if(data) setData(null)
  }

  const callApi = useCallback(async (endpoint, payload = null) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post(endpoint, payload, getHeaders());
      
      if(response?.data?.success){

        let data = response?.data?.responseData;
        setData(data);
        setLoading(false);
        return data;
      }else{
        let error = response?.data?.errors?.[0]?.errorMessage || response?.data?.responseMessage || response?.data?.message;
        setError(error);
        throw error
      }
    } catch (err) {
      console.error(err);
      let error = typeof err === "string" ? err : err?.message;
      if(typeof error === "string"){
        throw error;
      }
    } finally {
      setLoading(false);
    }
  }, []);

  return { loading, error, data, callApi, resetData };
};

export default useApi;
