import React, { useRef, useState } from "react";
import KmnTextfield from "src/components/shared/KmnTextfield";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { useRecoilValue } from "recoil";
import { atm_ManageCategoriesData } from "src/state/atomManageCategories";
import { attributeCode } from "./constants";
import CategoryPopUp from "src/components/ManageCategories/CategoryPopUp";
import { Col } from "src/components/shared";
import SearchIcon from "@mui/icons-material/Search";
import { autocompleteClasses } from "@mui/material";
import useDeviceType from "src/hooks/useDeviceType";
import { AddCircleRounded } from "@mui/icons-material";
import { atm_merchantState } from "src/state/atomsTransactionData";

const CategoryRuleFields = ({
  categoryRuleFields,
  index,
  removeCategoryField,
  attributes,
  handleCategoryCondition,
  data,
  isSaveClicked,
  resetError,
  addCategoryField,
  selectedTags,
  resetApiError
}) => {
  const valueRef = useRef(null);
  const selectedAttribute = attributes?.find((d) => d?.id === data?.attribute);
  const [isValueDropdownOpen, setIsValueDropdownOpen] = useState(false);
  const isAttributeCategory =
  selectedAttribute?.code === attributeCode.CATEGORY;
  const isAttributeMerchant =
  selectedAttribute?.code === attributeCode.MERCHANT;
  const categoriesData = useRecoilValue(atm_ManageCategoriesData);
  const [searchedValue, setSearchedValue] = useState("");
  const instantSearchedValue = useRef(null);
  const [isListAppeared, setIsListAppeared] = useState(false);
  const { isMobile } = useDeviceType();
  const showRemoveIcon = categoryRuleFields?.length > 1;
  const showAddIcon = categoryRuleFields?.length - 1 === index && categoryRuleFields?.length < 2;
  const isValueEmpty =
    (typeof data?.value === "string" && !data?.value?.trim()) || !data?.value;
  const merchantFilterData = useRecoilValue(atm_merchantState);

  let options = [];
  let optionKey = "";
  let optionValue = "";
  if (isAttributeMerchant) {
    options = merchantFilterData;
    optionKey = "providerName";
    optionValue = "providerId";
  }
  if (isAttributeCategory) {
    options = categoriesData
      .flatMap((d1) => d1?.categories)
      ?.flatMap((d2) =>
        d2?.categories?.map((d3) => ({
          ...d3,
          name: `${d2?.name} - ${d3?.name}`,
        }))
      );
    optionKey = "name";
    optionValue = "kmnId";
  }

  const lastRightField = [...categoryRuleFields]?.pop();

  const shouldAddRightField = lastRightField?.attribute && ((typeof lastRightField?.value === "string" && lastRightField?.value?.trim()) || lastRightField?.value)

  const addRightField = () => {
    if(shouldAddRightField){
      addCategoryField();
    }
  }

  const handleValueOpen = () => {
    setIsValueDropdownOpen(true);
    if (data?.value) {
      setIsListAppeared(false);
    }
  };
  const handleValueClose = () => {
    setIsValueDropdownOpen(false);
  };

  const handleFieldChange = async (e) => {
    
    resetError();
    resetApiError();
    let name = e.target.name || e?.target?.title;
    let value = e.target.value;
    handleCategoryCondition({ name, value, index });
    if (name === "value") {
      if (value) {
        setIsListAppeared(true);
      }
      handleValueClose();
    }
    if (name !== "value") {
      handleCategoryCondition({ name: "value", value: "", index });
    }
  };

  const handleOnBlurMerchant = () => {
    setSearchedValue("");
    instantSearchedValue.current = "";
    if (data?.value) {
      setIsListAppeared(true);
    } else {
      setIsListAppeared(false);
    }
  };

  const handleInstantSearch = (value) => {
    instantSearchedValue.current = value;
    if (isListAppeared) {
      setIsListAppeared(false);
    }
  };

  const handleSearchedValue = (value) => {
    setSearchedValue(value);

    if (value?.trim()) {
      setIsListAppeared(true);
    }
  };

  const CreateNewMerchantButton = (props) => {
    return (
      <div
        {...props}
        style={{
          fontStyle: "italic",
          fontSize: 10,
          textAlign: 'center'
        }}
      >
        “Create Merchant” coming soon!
      </div>
    );
  };

  return (
    <>
      <KmnTextfield
        select
        error={
          (!selectedTags?.length && isSaveClicked && !data?.attribute) ||
          (!data?.attribute && !isValueEmpty && isSaveClicked)
        }
        errorMessage="Attribute is required"
        label="Attribute"
        name="attribute"
        value={data?.attribute}
        onChange={handleFieldChange}
        options={attributes}
        optionKey="name"
        optionValue="id"
        optionClassName="rulesOption"
        optionProps={{
          hidden: (option) => {
            return categoryRuleFields?.some(
              (field) => field?.attribute === option?.value
            );
          },
        }}
      />
      <KmnTextfield
        className="valueFieldCover"
        size={isMobile ? "small" : undefined}
        error={
          (!selectedTags?.length && isSaveClicked && isValueEmpty) ||
          (isValueEmpty && data?.attribute && isSaveClicked)
        }
        errorMessage="Value is required"
        open={isValueDropdownOpen}
        inputRef={valueRef}
        onOpen={handleValueOpen}
        autoComplete={isAttributeMerchant}
        filterOptions={(option) =>
          searchedValue?.trim()
            ? option?.filter((d) =>
                d?.[optionKey]
                  ?.toLowerCase()
                  .includes(searchedValue?.trim()?.toLowerCase())
              )
            : option
        }
        optionClassName="rulesOption"
        hideOptions={!searchedValue?.trim()}
        onKeyDownCapture={(e) => {
          if (isAttributeMerchant) {
            let value = e.target.value;
            if (e.key === "Enter" || e.keyCode === 13) {
              e.stopPropagation();
              if (value) {
                handleSearchedValue(e.target.value);
              }
            }
            if (!value) {
              setSearchedValue("");
            }
          }
        }}
        inputProps={{
          onKeyDown: (e) => {
            if (e.key === "Enter" || e.keyCode === 13) {
              e.stopPropagation();
            }
          },
        }}
        onClose={handleValueClose}
        onBlur={handleOnBlurMerchant}
        select={!isAttributeMerchant}
        name="value"
        label="Value"
        onInputChange={(_, newInputValue) => {
          handleInstantSearch(newInputValue);
        }}
        value={data?.value}
        onChange={handleFieldChange}
        options={
          searchedValue?.trim()
            ? options?.filter(
                (d) =>
                  d?.[optionKey]
                    ?.toLowerCase()
                    .includes(searchedValue?.trim()?.toLowerCase()) ||
                  d?.[optionValue] === data?.value
              )
            : options
        }
        optionKey={optionKey}
        optionValue={optionValue}
        popupIcon={!isListAppeared ? <SearchIcon /> : undefined}
        slotProps={
          !isListAppeared
            ? {
                popupIndicator: {
                  onClick: () => {
                    handleSearchedValue(instantSearchedValue.current);
                  },
                },
              }
            : undefined
        }
        sx={{
          [`& .${autocompleteClasses.popupIndicator}`]: {
            transform: !isListAppeared ? "none" : undefined,
          },
        }}
        noOptionsText={
          <Col gap={5}>
            <span className="noOptionsFound">No Merchant Found</span>
            <CreateNewMerchantButton />
          </Col>
        }
        additionalOption={
          <CreateNewMerchantButton
            style={{ paddingInline: 5, paddingTop: 5 }}
          />
        }
        customDropdown={isAttributeCategory}
      />
      {valueRef.current?.node && isAttributeCategory && <CategoryPopUp
        anchorValue={valueRef.current?.node}
        isOpen={isValueDropdownOpen}
        onClose={handleValueClose}
        name="value"
        onValueChange={handleFieldChange}
      />}
      <div className="fieldActionBtns">
        {showRemoveIcon && (
          <RemoveCircleIcon
            className="removeIcon"
            onClick={() => removeCategoryField(index)}
          />
        )}
        {showAddIcon && (
          <AddCircleRounded
            className="addIcon"
            onClick={addRightField}
            style={!shouldAddRightField ? {
              color: "var(--colorBlack9)",
              cursor: 'not-allowed'
            } : undefined}
          />
        )}
      </div>
    </>
  );
};

export default CategoryRuleFields;
