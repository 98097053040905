import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import KmnModal from "../../shared/KmnModal";
import { Row } from "../../shared";
import EditRule from "./EditRule";
import useRules from "./useRules";
import { attributeCode, RULE_MODE, RULES_ERROR_TYPE } from "./constants";
import { DeleteIcon, EditIcon, ErrorIcon } from "src/libs/icons";
import KmnButton from "../Transactions/KmnButton";
import useDeviceType from "src/hooks/useDeviceType";
import { usdFormate } from "src/global/central";
import { Checkbox, FormControlLabel, Popover, Switch } from "@mui/material";

const ManageRulesModal = ({ cancelManageRulesModal }) => {
  const { data, fns } = useRules();
  const [ruleMode, setRuleMode] = useState("");
  const [ruleToBeDeleted, setRuleToBeDeleted] = useState(null);
  const bodyRef = useRef(null);
  const footerRef = useRef(null);
  const { isMobile } = useDeviceType();
  const [selectedForEdit, setSelectedForEdit] = useState(null);
  const [activeRuleRef, setActiveRuleRef] = useState(null);
  const [isEditRuleConfirmation, setIsEditRuleConfirmation] = useState(false);
  const [rules, setRules] = useState([]);
  const isRunNowChecked = useRef(false)

  useEffect(() => {
    setRules(data?.rulesData?.rules);
  },[data?.rulesData])

  const triggerCreateRule = () => {
    setRuleMode(RULE_MODE.CREATE);
  };

  useLayoutEffect(() => {
    if (ruleMode === RULE_MODE.CREATE) {
      setTimeout(() => {
        bodyRef.current?.scrollIntoView({ block: "end" });
      }, 0);
    }
  }, [ruleMode]);

  const cancelEditOrCreateRule = () => {
    setRuleMode("");
    setSelectedForEdit(null);
    fns.resetError();
  };

  useEffect(() => {
    fns.getRuleAttributes();
    fns.getRuleValues();
    fns.getAllRules();
  }, []);

  const onDeleteRule = (rule) => {
    setRuleToBeDeleted(rule);
  };

  const onCancelDeleteRule = () => {
    fns.resetError();
    setRuleToBeDeleted(null);
  };

  const onConfirmDeleteRule = async () => {
    fns.resetError();
    if (data.deleteRuleLoading) return;
    await fns.deleteRule(ruleToBeDeleted?.ruleId);
    onCancelDeleteRule();
  };

  const handleEditRule = (rule) => {
    if(rule?.readyToRun){
      setIsEditRuleConfirmation(true);
    }else{
      setRuleMode(RULE_MODE.EDIT);
    }
    setSelectedForEdit(rule);
  };

  const onCancelEditRuleConfirmation = () => {
    setIsEditRuleConfirmation(false);
    setSelectedForEdit(null);
  }

  const onConfirmEditRuleConfirmation = () => {
    setRuleMode(RULE_MODE.EDIT);
    setIsEditRuleConfirmation(false);
  }

  const handleRulesState = async (e, ruleId) => {
    const isChecked = e?.target?.checked;
    setActiveRuleRef(isChecked ? e.target : null);
    
    setRules(prev => 
      prev.map(d => 
        d?.ruleId === ruleId ? { ...d, readyToRun: isChecked } : d
      )
    );
  
    if (!isChecked) {
      fns.setRunStatus({ ruleId, activateRule: false, runNow: false });
    }
  };

  const handleRuleStatus = (rule_id, checked = null) => {
    if(data.loading) return;
    setActiveRuleRef(null);
    if(checked !== null) {
      fns.setRunStatus({ruleId: rule_id, activateRule: true, runNow: checked})
    }else{
      setRules(prev => 
        prev.map(d => 
          d?.ruleId === rule_id ? { ...d, readyToRun: false } : d
        )
      );
    }
    isRunNowChecked.current = false;
  }

  return (
    <>
      <KmnModal
        isOpen
        onClose={cancelManageRulesModal}
        className="manageRulesModal"
        title="Manage Rules"
        subTitle="Create and manage custom rules to automatically map your transactions to appropriate merchants/ categories and more"
        width={1000}
        hideCancel
        btnVariant="tertiary"
        confirmBtnLabel="Create Rule"
        loading={(data.loading && !ruleMode) || data.deleteRuleLoading || data.rulesLoading}
        disableFooterActions={!!ruleToBeDeleted}
        footerRef={footerRef}
        footer={
          ruleToBeDeleted && (
            <div className="deleteConfirmation">
              {data.error && data.errorType === RULES_ERROR_TYPE.DELETE && (
                <div className="error">
                  <ErrorIcon /> {data.error}
                </div>
              )}
              <div className="deleteConfirmationInside">
                <p className="message">Are you sure you want to delete Rule : “{ruleToBeDeleted?.ruleName}” ?</p>
                <div className="btns">
                  <KmnButton variant={isMobile ? "secondary" : "tertiary"} color="primary_outlined" onClick={onCancelDeleteRule}>
                    Cancel
                  </KmnButton>
                  <KmnButton isDisabled={data.deleteRuleLoading} variant={isMobile ? "secondary" : "tertiary"} color="primary" onClick={onConfirmDeleteRule}>
                    Confirm
                  </KmnButton>
                </div>
              </div>
            </div>
          )
        }
        footerDivider
        disableFooter={ruleMode}
        onConfirm={triggerCreateRule}
        body={
          <>
            <div className="rules" ref={bodyRef}>
              {rules?.map((d) => (
                <React.Fragment key={d?.ruleId}>
                  <div hidden={d?.ruleId === selectedForEdit?.ruleId && ruleMode === RULE_MODE.EDIT} key={d?.ruleId}>
                    <div className="ruleCount">
                      <div>
                        <div className="ruleCountLabel">Rule : {d?.ruleName} </div>
                        <div className="transactionUpdateLabel">{d?.countOfTrasactionsUpdatedInLast24Hrs} transactions updated in the last 24 hrs</div>
                      </div>
                      <span className="setToRun">
                        Set to run <Switch id={`${d?.ruleId}`} size={isMobile ? "small" : "medium"} checked={d?.readyToRun} onChange={(e) => handleRulesState(e, d?.ruleId)} />
                      </span>
                    </div>
                    <Popover
                      open={!!(d?.readyToRun && activeRuleRef && activeRuleRef?.id == d?.ruleId)}
                      anchorEl={activeRuleRef}
                      id={activeRuleRef}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                      }}
                    >
                      <div className="activeRuleOptions">
                        <p>This action will apply the rule from the next scheduled run onwards</p>
                        <p>
                        <FormControlLabel  className="runNowLabel" onChange={(e) => isRunNowChecked.current = e.target.checked} control={<Checkbox size="small" />} label="Run Now" />
                        </p>
                        <div className="btns">
                          <KmnButton onClick={() => handleRuleStatus(d?.ruleId)} variant={isMobile ? "secondary_xsmall" : "secondary"} color="primary_outlined">Cancel</KmnButton>
                          <KmnButton onClick={() => handleRuleStatus(d?.ruleId, isRunNowChecked.current)} variant={isMobile ? "secondary_xsmall" : "secondary"} color="primary" >Confirm</KmnButton>
                        </div>
                      </div>
                    </Popover>
                    <div className="ruleBoxes">
                      <div className="ruleBoxesInside">
                        <div className="conditionsWrapper ruleCol">
                          {d?.conditions?.map((condition, idx) => (
                            <div key={condition?.conditionId} className="conditions">
                              <div className="rule attribute">
                                {idx > 0 && <b style={{ textDecoration: "underline" }}>and,</b>} {condition?.attributeName} {condition?.operatorName} {condition?.attributeCode === attributeCode.AMOUNT ? usdFormate(condition?.attributeIdValueName) : `“${condition?.attributeIdValueName}”`}
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="resultsWrapper ruleCol">
                          {d?.results?.map((result) => (
                            <div key={result?.resultId} className="results conditions">
                              <div className="rule">
                                {result?.attributeName} as “{result?.attributeIdValueName}”
                              </div>
                            </div>
                          ))}
                          <Row className="tagsWrapper">{d?.resultsTags?.length > 0 && <div className="tags rule">Tags : {d?.resultsTags?.map((d) => d?.tagName)?.join(", ")}</div>}</Row>
                        </div>
                      </div>
                      {!ruleMode && !ruleToBeDeleted && (
                        <Row gap={15} className="actionIcons">
                          <EditIcon className="editIcon" onClick={() => handleEditRule(d)} />
                          <DeleteIcon className="deleteIcon" onClick={() => onDeleteRule(d)} />
                        </Row>
                      )}
                    </div>
                  </div>
                  {ruleMode === RULE_MODE.EDIT && selectedForEdit?.ruleId === d?.ruleId && <EditRule selectedForEdit={selectedForEdit} cancelEditOrCreateRule={cancelEditOrCreateRule} ruleMode={ruleMode} data={data} fns={fns} />}
                </React.Fragment>
              ))}
              {ruleMode === RULE_MODE.CREATE && <EditRule selectedForEdit={selectedForEdit} cancelEditOrCreateRule={cancelEditOrCreateRule} ruleMode={ruleMode} data={data} fns={fns} />}
            </div>
          </>
        }
      />
      <KmnModal width={500} className="rulesWarningModal" isOpen={isEditRuleConfirmation} title="Edit Rule Confirmation" discardBtnLabel="No" confirmBtnLabel="Yes" btnVariant={isMobile ? "secondary_small" : "primary_small"} onConfirm={onConfirmEditRuleConfirmation} onCancel={onCancelEditRuleConfirmation} onClose={onCancelEditRuleConfirmation} body={
        <>
          <p className="warning">Editing an active rule will mark the rule inactive.</p>
          <p className="warning warningConfirm">Do you want to proceed ?</p>
        </>
      } />
    </>
  );
};

export default ManageRulesModal;
